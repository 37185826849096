/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import { difference } from '../lib/Helper'
import { ServerEventEnum } from './ServerEventEnum'
import { fcLogger } from '../lib/FcLogger'

export class PollingHandler {
  /**
   *
   * @param callback: the fn to be called with the response of the resource
   * @param resourceURL: the URL of the resource
   * @param currentConfig: the current config of the player
   */
  constructor ({callback, resourceURL, currentConfig = {}}) {
    this.resourceURL = resourceURL
    this.authHeaders = {}
    this.callback = callback
    this.lastEventId = '' // should be given by the embedder on init

    this.currentConfig = {...currentConfig, ...currentConfig.player_config}
    this.currentChapters = currentConfig.chapters || []
    this.currentDisplay = currentConfig.player_display

    // Object.assign(this.currentConfig, { dvr: true, autoplay: true })
    this.pollingDelay = 3000
    this.lastEventId = ''

    fcLogger.log('Polling...')
    this._poll().catch(error => {
      fcLogger.warn('Could not get the information while polling.')
    })
  }

  _poll () {
    return fetch(this.resourceURL, {headers: this.authHeaders}).
      then(response => response.json()).
      then(response => this.handleResponse(response)).
      then(response => setTimeout(this._poll.bind(this), this.pollingDelay)).
      catch((e) => {
        fcLogger.warn('Error while polling, retrying...', e)
        setTimeout(this._poll.bind(this), this.pollingDelay)
      })
  }

  handleResponse (response) {
    this.handlePlayerConfig(
      {...response.player.setup, ...response.video.player_config})
    this.handleChapters(response.video.chapters)
    this.handlePlayerDisplay(response.video.player_display)
  }

  handleChapters (chapters) {
    if (JSON.stringify(chapters) === JSON.stringify(this.currentChapters)) return
    // TODO: handle chapters
    this.currentChapters = chapters
  }

  handlePlayerDisplay (playerDisplay) {

    if (JSON.stringify(playerDisplay) === JSON.stringify(this.currentDisplay)) return

    this.callback(new MessageEvent('polling',
      {
        data: {
          event: ServerEventEnum.CONTENT_UPDATED,
          data: {
            content: {
              id: null,
              type: null,
            },
            changes: {
              player_display: playerDisplay,
            },
          },
        },
      }))

    this.currentDisplay = playerDisplay
  }

  handlePlayerConfig (playerConfig) {

    const changes = difference(playerConfig, this.currentConfig)
    Object.keys(changes).forEach((key, index) => {
      this.callback(new MessageEvent('polling',
        {
          data: {
            event: ServerEventEnum.PLAYER_CONFIG_UPDATED,
            data: {
              content: {
                id: null,
                type: null,
              },
              changes: {
                [key]: changes[key],
              },
            },
          },
        }))
    })

    this.currentConfig = playerConfig
  }
}
