/**
 * @package   freecaster/luna/player
 * @author    Maxime Bartier <maxime.bartier@freecaster.com>
 * @copyright Freecaster 2019
 */

import { FlowPlayerFeatureBase } from "../FlowPlayerFeatureBase";
import { UIClasses } from "anyplayer/src/FlowNPlayer.js";
import labelsMap from "../../../lib/mapTooltipLabels";
import { createHtmlElementFromString } from '../../../lib/Helper'

export class TooltipLabelsFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor(player) {
    super(player);

    this.mapClasses = UIClasses.elements;
    // to avoid updating anyplayer
    Object.assign(this.mapClasses, { 'forward': 'fc-icon-forward' })

    this._player.player.on('loadeddata', () => this.createTooltip())
  }

  getName() {
    return 'TooltipLabelsFlowPlayerFeature'
  }

  createTooltip(){
      // TODO: create config -> this._player.config.draw_tooltip
      //Boolean for activate the all tooltips
      if (true) {
        this.renderLabels();
      }
  }

  renderLabel (label) {
    return createHtmlElementFromString(`
    <div class="fp-tooltip">
        <span class="fp-tooltip__label">${label}</span>
    </div>
    `)
  }

  renderLabels() {
    Object.keys(labelsMap).forEach(key => {
      if (key in this.mapClasses) {
        const el = this._player.container.getElementsByClassName(this.mapClasses[key])[0];

        if (!el) return
        if (el.classList.contains('has-tooltip')) return

        const label = this.renderLabel(labelsMap[key]);

        el.classList.add("has-tooltip");
        el.appendChild(label);
      }
    });
  }
}
