/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import {PlayerProperty} from '../PlayerProperty'
import {fcLogger} from '../../../../../lib/FcLogger'
import {cast} from '../../../../../lib/Helper'
import _get from 'lodash/get'

class ShouldReloadProperty extends PlayerProperty {
  /**
   *
   * @param player
   * @param {ServerEvent} value
   * @return {Promise<void>}
   */
  async handle(player, value) {

    const shouldReload = cast(value.target.attributes.should_reload)
    const isLive = cast(value.target.attributes.live)

    if (!shouldReload) return

    const embedURL = fcEmbedder.getEmbedUrl(player.container)

    try {
      const response = await fetch(embedURL)
      const config = await response.json()
      const sources = config.video.src

      if (!sources)
        return console.warn('Sources missing from config')

      // in order to be sure that we are on a livestream, we need to wait upon
      // the next loadedmetadata event and dig in hlsjs to check the live property
      const handleLoadeddata = () => {
        player.player.setOpts({ live: isLive })
        // since it is a reload, features might have to use some info coming from Luna
        player.fc_features.call('reload', config)
        player.player.removeEventListener('loadeddata', handleLoadeddata, true)
      }

      player.player.on('loadeddata', handleLoadeddata, true)

      if (_get(player, 'player.hls')) {
        player.player.hls.destroy()
      }

      // we need to update the type of the video
      player.config.video_type = _get(config, 'video.video_type')

      player.player.setOpts({ autoplay: true })
      player.player.setSrc(sources)
      fcLogger.info('Source has been switched successfully', sources)

    } catch (error) {
      console.warn('Could not load config', error.message)
    }
  }

  getName () {
    return 'should_reload'
  }
}

export const shouldReloadProperty = new ShouldReloadProperty()
