/**
 * @package   freecaster/luna/player
 * @author    Maxime Bartier <maxime.bartier@freecaster.com>
 * @copyright Freecaster 2019
 */

import {FlowPlayerFeatureBase} from '../../FlowPlayerFeatureBase'
import _get from 'lodash/get'
import _isNumber from 'lodash/isNumber'
import moment from 'moment-timezone'
import {isNumeric} from '../../../../lib/Helper'
import {CHAPTER_EVENTS} from './ChapterEvents'

export class ChaptersFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor(player) {
    super(player)

    this.absoluteTimeFeature = null
    this.timeHelper = {
      chapterEnterTimes: [],
      newPosition:  0,
      oldPosition: _get(this, '_player.config.start', 0)
    }

    // provide a way to get the currentChapter from the playerInstance
    // ie. fcplayer().fc_getCurrentChapter()
    this._player.fc_getCurrentChapter = this.getCurrentChapters.bind(this)
    this._player.on('fcPreviewPlayer', this.onPreviewPlayer.bind(this))
    this._player.on('fcRemovePreviewPlayer', this.onPreviewPlayer.bind(this))

    this.chapters = this._player.config.chapters || []

    this.chapterStyle = 'dot'
    this.createChapterViewer()

    this.durationMs = 0

    const nowTs = parseInt(moment().format('x'))

    // If the video has no start, we define one ourselves
    this.videoStart = _get(this, '_player.config.start') || nowTs

    this.snapshot = {
      timestamp: nowTs,
    }

    this._player.player.on('loadeddata', () => {
      this.durationMs = this.getDurationMs()

      this.reBuild()
      setInterval(this.checkChapters.bind(this), 1000)

      // TODO: handle DASH
      if (this._player.config.live && _get(this, '_player.player.hls'))
        this.registerLiveEvents()
    })
  }
  handle () {
    this.absoluteTimeFeature = this._player.fc_features.get('AbsoluteTimeFlowPlayerFeature')
  }

  checkChapters() {
    const chapterEnter = (chapter) => {
      // mark the time user has entered the chapter (used to compute how much time he sent watching the chapter)
      this.timeHelper.chapterEnterTimes.push({ id: chapter.id, enteredAt: moment() })
      this._player.trigger(CHAPTER_EVENTS.USER_ENTER, { data: chapter})
    }
    const chapterLeave = (chapter) => {
      const chapterEnter = this.timeHelper.chapterEnterTimes.find(c => c.id === chapter.id)

      if (_get(chapterEnter, 'enteredAt') instanceof moment) {
        chapter.timeSpent = moment().diff(chapterEnter.enteredAt)
      }

      // we need to reset that entry, only unique chapter ids should be in that array
      this.timeHelper.chapterEnterTimes = this.timeHelper.chapterEnterTimes.filter(c => c.id !== chapter.id)
      this._player.trigger(CHAPTER_EVENTS.USER_LEAVE, { data: chapter })
    }
    const chapterMark = (chapter) => {
      this._player.trigger(CHAPTER_EVENTS.USER_MARK, { data: chapter})
    }

    this.timeHelper.newPosition = this._player.currentTimestamp

    if ((this.timeHelper.newPosition > this.timeHelper.oldPosition) && (this.timeHelper.oldPosition >= 0))
    {
      this.getPublishedChapters().forEach(chapter => {
        if (chapter.end > chapter.start) {
          if ((this.timeHelper.newPosition >= chapter.end) && (this.timeHelper.oldPosition >= chapter.start) && (this.timeHelper.oldPosition < chapter.end))
          {
            chapterLeave(chapter)
          }
          else if ((this.timeHelper.oldPosition < chapter.start) && (this.timeHelper.newPosition >= chapter.start) && (this.timeHelper.newPosition < chapter.end))
          {
            chapterEnter(chapter)
          }
        }
        else if ((this.timeHelper.oldPosition <= chapter.start) && (this.timeHelper.newPosition > chapter.start) && (this.timeHelper.newPosition < this.timeHelper.oldPosition + 3000) && (this._player.playerState['is-playing'])) {
          chapterMark(chapter)
        }
      })
    }
    else if (this.timeHelper.newPosition < this.timeHelper.oldPosition)
    {
      this.getPublishedChapters().forEach(chapter => {
        if (chapter.end > chapter.start)
        {
          if ((this.timeHelper.newPosition < chapter.start) && (this.timeHelper.oldPosition >= chapter.start) && (this.timeHelper.oldPosition < chapter.end))
          {
            chapterLeave(chapter)
          }
          else if ((this.timeHelper.oldPosition >= chapter.end) && (this.timeHelper.newPosition >= chapter.start) && (this.timeHelper.newPosition < chapter.end))
          {
            chapterEnter(chapter)
          }
        }
      })
    }
    this.timeHelper.oldPosition = this.timeHelper.newPosition
  }

  getCurrentChapters() {
    const currentChapters = this.chapters.filter(chapter => {
      const cp = this.convertChapterValue(chapter)

      const currentMoment = this._player.currentTimestamp

      if (cp.start && cp.end)
        return cp.start <= currentMoment && cp.end >= currentMoment

      return cp.start <= currentMoment
    })

    // Piwik asked to return a dummy chapter 'main' if there are no current chapter
    const mainChapter = { title: 'main' }
    return currentChapters.length === 0 ? [mainChapter] : currentChapters
  }

  getName() {
    return 'ChaptersFlowPlayerFeature'
  }

  onPreviewPlayer() {
    this.reBuild()
  }

  getPublishedChapters() {
    return this.chapters.filter(chapter => chapter.published === true)
  }

  getChapters () {
    return _get(this, '_player.config.preview_mode') === true
      ? this.chapters
      : this.getPublishedChapters()
  }

  addOrUpdate (chapter) {
    // if the chapter cannot be edited, it will be added
    if (!this.edit(chapter)) {
      this.add(chapter)
    }

    this.reBuild()
  }

  edit(chapter) {
    let chapterIndex = this.chapters.map(cp => cp.id).indexOf(chapter.id)

    if (~chapterIndex) {
      this.chapters[chapterIndex] = { ...this.chapters[chapterIndex], ...chapter}
      return true
    }

    return false
  }

  add(chapter) {
    this.chapters.push(chapter)
  }

  remove(chapter) {
    this.chapters = this.chapters.filter(cp => cp.id !== chapter.id)
    this.reBuild()
  }

  registerLiveEvents() {

    setInterval(() => {
      if (!this._player.readyState > 0 ) return
      this.reBuild()
    }, 1000)

    this._player.player.hls.on('hlsLevelLoaded', (eventName, payload) => {
      this.durationMs = this.getDurationMs(payload.details.fragments)
    })
  }

  /**
   * Get the duration of the video
   * OR the max DVR available time
   * @returns {number}
   */
  getDurationMs (fragments = []) {
    if (fragments.length > 1) {
      return fragments
        .map(fragment => fragment.duration)
        .reduce((accumulator, value) => parseFloat(accumulator) + (parseFloat(value) || 0))
        * 1000
    }

    return this._player.duration * 1000
  }

  renderChapterViewerTemplate ({title, description}) {
    return `
      <span class="title">${title || ''}</span>
      <span class="description">${description || ''}</span>
    `
  }

  createChapterViewer () {
    if (this._player.container.querySelector('.fc-chapter-viewer') || !this.timeline) return

    const chapterViewer = document.createElement('div')
    chapterViewer.classList.add('fc-chapter-viewer')
    this.timeline.appendChild(chapterViewer)
    this.chapterViewer = chapterViewer
  }

  showChapterViewer (chapter, $event) {
    const template = this.renderChapterViewerTemplate(chapter)
    const time = this.timestampBox(chapter).html
    this.chapterViewer.classList.add('active')
    this.chapterViewer.innerHTML = time + template
    this.editChapterViewerPos(this.chapterViewer, $event)
  }

  timestampBox (chapter) {
    let elem = this._player.container.querySelector('.fp-timestamp')

    if (this.absoluteTimeFeature && Number.parseInt(_get(chapter, 'start')).toString().length === 13) {
      elem.innerText = this.absoluteTimeFeature.formatDatetime(moment(_get(chapter, 'start')));
    }

    if(!elem.querySelector('span'))
      elem.innerHTML = `<span>${elem.innerHTML}</span>`

    return {
      html: elem.innerHTML,
      hide: () => elem.setAttribute('style', 'opacity:0;'),
      show: () => elem.setAttribute('style', 'opacity:1;'),
    }
  }

  editChapterViewerPos (chapterViewer, $event) {
    const parentLeft = this.timeline.offsetLeft
    const left = $event.target.offsetLeft - parentLeft

    if (chapterViewer.clientWidth > left) {
      if (chapterViewer.classList.contains('tooltip-on-right')) {
        chapterViewer.classList.remove('tooltip-on-right')
      }
      chapterViewer.classList.add('tooltip-on-left')
    } else {
      if (chapterViewer.classList.contains('tooltip-on-left')) {
        chapterViewer.classList.remove('tooltip-on-left')
      }
      chapterViewer.classList.add('tooltip-on-right')
    }
    chapterViewer.setAttribute('style', `left:${left}px`)
  }

  renderChapter (chapter) {
    // start & end are 'ms' or 'timestamp' values
    let startOffset = this._player.config.live && this._player.currentTimestamp
      ? this._player.currentTimestamp - (this._player.currentTime * 1000)
      : parseInt(moment(this.videoStart).format('x'))

    let startPercentage = (chapter.start - startOffset) / this.durationMs * 100
    const durationPercentage = (chapter.end - chapter.start) / this.durationMs * 100

    const cp = document.createElement('div')

    cp.classList.add('fc-chapter')
    cp.classList.add(`fc-chapter--${this.chapterStyle}`)

    // TODO: do not render if not seekable
    let styles = []

    styles.push(`left: ${startPercentage}%`)

    if (durationPercentage)
      styles.push(`width: ${durationPercentage}%`)

    // if the chapter is going out of the screen, hide it
    if (startPercentage < 0 || startPercentage > 100)
      styles = ['display: none']

    cp.setAttribute('style', styles.join(';'))

    if (this.timeline) this.timeline.appendChild(cp)

    cp.addEventListener('mousemove', ($event) => {
      this.showChapterViewer(chapter, $event)
    })
    cp.addEventListener('mouseover', () => {
      this.timestampBox().hide()
    })
    cp.addEventListener('mouseleave', () => {
      this.timestampBox().show()
      if (this.chapterViewer.classList.contains('active')) {
        this.chapterViewer.classList.remove('active')
      }
    })
  }

  chaptersDrawer () {
    this.convertChaptersValues()
    this.getChapters().map(this.renderChapter.bind(this))
  }

  /**
   * Destroy all the elements in timeline
   */
  destroy () {
    const allFpChapters = this._player.container.getElementsByClassName('fc-chapter')
    if (!allFpChapters) return

    Array.from(allFpChapters).forEach(cp => cp.remove())
  }

  reBuild () {
    this.destroy()
    this.chaptersDrawer()
  }

  convertChapterValue(chapter) {
    function isMs (num) {
      return _isNumber(num) && num < 307373100000
    }

    let start, end

    if(isMs(chapter.start)) {
      start = moment(this.videoStart).add(chapter.start, 'ms').format('x')
    } else {
      const cpstart = isNumeric(chapter.start)
          ? parseInt(chapter.start)
          : chapter.start

      start = moment(cpstart).format('x')
    }

    if(isMs(chapter.end)) {
      end = moment(this.videoStart).add(chapter.end, 'ms').format('x')
    } else {
      const cpend = isNumeric(chapter.end)
        ? parseInt(chapter.end)
        : chapter.end

      end = moment(cpend).format('x')
    }

    return {
      ...chapter,
      // moment.format('x') returns a string, we need to convert it to int
      start: parseInt(start),
      end: parseInt(end)
    }
  }

  convertChaptersValues () {
    this.chapters = this.chapters.map(this.convertChapterValue.bind(this))
  }
}
