/**
 * Text position available for player displays
 * @type {{TOP_LEFT: string, BOTTOM_CENTER: string, TOP_CENTER: string, BOTTOM_RIGHT: string, MIDDLE_CENTER: string, TOP_RIGHT: string, MIDDLE_RIGHT: string, BOTTOM_LEFT: string, MIDDLE_LEFT: string}}
 */
export const playerDisplayTextPositionEnum = {
  TOP_LEFT: 'topleft',
  TOP_CENTER: 'topcenter',
  TOP_RIGHT: 'topright',
  MIDDLE_LEFT: 'middleleft',
  MIDDLE_CENTER: 'middlecenter',
  MIDDLE_RIGHT: 'middleright',
  BOTTOM_LEFT: 'bottomleft',
  BOTTOM_CENTER: 'bottomcenter',
  BOTTOM_RIGHT: 'bottomright',

  has(position) {
    if (typeof position !== 'string') return false
    return Object.values(this).includes(position)
  }
}
