/**
 * @package   freecaster/luna/player
 * @author    Yannick Delwiche <yannick.delwiche@freecaster.com>
 * @copyright Freecaster 2019
 */

import * as ajax from './lib/ajax'
import CallbackService from './CallbackService'
import { playerFactory } from './players/PlayerFactory'
import { cast } from './lib/Helper'

export default class Embedder {
  constructor (userConfig) {
    // Default configuration
    this.config = {
      embedRoot: userConfig.embedRoot || 'https://player.freecaster.com/embed/',
      interval: 200,
      isIframe: false,
    }

    this.timer = null
    this.loadedFiles = []

    // Merge with custom configuration
    this.mergeConfig(userConfig)

    new CallbackService(this.config.isIframe)

    this.readyToEmbed()
  }

  checkContainers () {
    document.querySelectorAll('.freecaster-player').forEach((element) => {
      if (element.dataset.fcLoaded) return

      element.dataset.fcLoaded = '1'

      ajax.get(this.getEmbedUrl(element), (data) => {
        this.loadPlayer(element, data)
      }, () => {
        console.warn(
          'Could not load player info for ' + element.dataset.fcToken)
      })
    })
  }

  getEmbedUrl (element) {

    const {fcToken, fcStreamPreset} = element.dataset

    if (typeof fcToken !== 'string')
      console.warn('fc-token attribute not provided')

    // cleaning embedURL
    const embedRootURL = new URL(this.config.embedRoot)
    let embedRoot = `${embedRootURL.protocol}//${embedRootURL.host}${embedRootURL.pathname.replace(/(\/{2,})/ig, '/')}`
    if (!embedRoot.endsWith('/')) embedRoot = `${embedRoot}/`

    // appends the token (video uuid)
    let url = `${embedRoot}${fcToken}.json`

    if (typeof fcStreamPreset === 'string' && fcStreamPreset.length > 0)
      url += `?stream_preset_id=${fcStreamPreset}`

    return url
  }

  initCheckInterval () {
    // Check for player containers
    if ((!this.timer) && (this.config.interval)) {
      this.timer = setInterval(this.checkContainers.bind(this),
        this.config.interval)
    }
  }

  isFileLoaded (file) {
    let loaded = this.loadedFiles.indexOf(file) !== -1
    if (!loaded) {
      this.loadedFiles.push(file)
    }
    return loaded
  }

  loadPlayer (element, data) {
    if (!data['player']) {
      console.warn('Missing player data', data);
      return;
    }

    element.embedder = playerFactory.create({
      element,
      data,
      type: data.player.name
    })
  }

  mergeConfig (userConfig) {
    for (let key in userConfig) {
      if (!userConfig.hasOwnProperty(key)) {
        continue
      }

      this.config[key] = cast(userConfig[key])
    }
  }

  readyToEmbed () {
    window.fcEmbedder = this;

    // Tell the world we are ready
    window.dispatchEvent(new CustomEvent('fcReadyToEmbed'));

    // Then check for player containers at regular intervals
    this.initCheckInterval();
  }
}
