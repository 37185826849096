/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019-20
 */

import {FlowPlayerFeatureBase} from '../FlowPlayerFeatureBase'
import _get from 'lodash/get'

export class DvrFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor (player) {
    super(player)
  }

  getName() {
    return 'DvrFlowPlayerFeature'
  }

  handle () {
    this.rewindFlowPlayerFeature = this._player.fc_features.get('RewindFlowPlayerFeature')

    const initialDvr = this.getDvrFromConfig(this._player.config)

    this._player.player.on('loadeddata', () => {
      // we can only know live status onloadedmetada
      if (typeof initialDvr === 'boolean') this.setDvr(initialDvr)
    })
  }

  /**
   *
   * @param config
   * @return {true|false|null} - returns the dvr value or null if not defined
   * in the config object
   */
  getDvrFromConfig(config) {
    return _get(config, 'player_config.dvr')
  }

  reload (config) {
    if (_get(this, 'config.live') === true) {
      const dvr = this.getDvrFromConfig(config)
      if (typeof dvr === 'boolean') this.setDvr(dvr)
    } else {
      // force enable DVR: dvr was disabled during live, when we switch to VOD
      // DVR should be enabled
      this.setDvr(true)
    }
  }

  setDvr (value) {
    if (_get(this, '_player.config.fcBackoffice') === true) return

    this._player.player.toggleDisable(!value)

    if (value) {
      if (this.timeline) this.timeline.show()
      this.rewindFlowPlayerFeature.addButton()

    } else {
      // we cannot disabled DVR on VOD
      if (!this._player.config.live) return

      // if the DVR has been disable, we must go to edge to stop user from
      // viewing the DVR archive
      this._player.gotoLive()

      if (this.timeline) this.timeline.hide()
      this.rewindFlowPlayerFeature.removeButton()
    }

  }
}
