/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import { FlowPlayerFeatureBase } from '../FlowPlayerFeatureBase'

export class QualitySelectorFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor (player) {
    super(player)

    const qsel = this.controlbar ? this.controlbar.querySelector('.fp-qsel') : null

    // set quality selector data attribute
    this._player.player.on(flowplayer.events.SET_QUALITY, (event) => {
      if (qsel) qsel.setAttribute('data-quality', event.data.text)
    })

    // set 'auto' as default quality
    this._player.player.on(flowplayer.events.QUALITIES, () => {
      if (!qsel) return

      const defaultQuality = 'auto'

      qsel.setAttribute('data-quality', defaultQuality)

      const qualities = qsel.querySelectorAll('.fp-menu li')

      for (var i = 0; i < qualities.length; i++) {
        if (qualities[i].textContent.toLowerCase() === defaultQuality) {
          qualities[i].classList.add('fp-on')
        }
      }

    })
  }
  getName() {
    return 'QualitySelectorFlowPlayerFeature'
  }
}
