/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import _get from 'lodash/get'
import youbora from 'youboralib'
import {muxHelper} from '../../MuxHelper'
import {CHAPTER_EVENTS} from './ChapterFeature/ChapterEvents'
import {fcLogger} from '../../../lib/FcLogger'
import {FlowPlayerFeatureBase} from '../FlowPlayerFeatureBase'
import {ATInternetHelper} from './ATInternet/ATInternetHelper'
import {VIDEO_TYPE} from '../../VideoTypes'

export class TrackingFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor(player) {
    super(player)

    if (_get(this, '_player.config.trackers')) {
      this.loadTrackers()
    }

    if (_get(this, '_player.config.trackers.atinternet'))
      new ATInternetHelper(this._player, this._player.config.trackers.atinternet)
  }

  getName() {
    return 'TrackingFlowPlayerFeature'
  }

  loadTrackers() {
    if (_get(this, '_player.config.trackers.mux')) this.loadMux()
    if (_get(this, '_player.config.trackers.youbora')) this.loadYoubora()
  }

  loadMux() {
    // @see: https://docs.mux.com/docs/web-integration-guide
    muxHelper.init(this._player, this._player.container)
  }

  loadYoubora() {
    const fireEvent = (event) => {
      const dimension = {
        chapter_title: event.data.title,
        chapter_id: event.data.id,
      }

      const start = Number.parseInt(event.data.start)
      const end = Number.parseInt(event.data.end)

      if (_get(event, 'data.timeSpent') && event.type === CHAPTER_EVENTS.USER_LEAVE) {
        const values = {
          TotalDuration: event.data.timeSpent / 1000
        }

        fcLogger.log('event fired to youbora', {type: event.type, dimension, values})
        return plugin.getAdapter().fireEvent(event.type, dimension, values)
      }

      if (start && end && event.type === CHAPTER_EVENTS.USER_ENTER) {
        Object.assign(dimension, { chapter_duration: (event.data.end - event.data.start) / 1000})
      }

      fcLogger.log('event fired to youbora', {type: event.type, dimension})
      plugin.getAdapter().fireEvent(event.type, dimension)
    }

    // http://developer.nicepeopleatwork.com/plugins/integration/js-browser/html5/
    // window.plugin is defined in flowplayer.js
    require('youbora-adapter-flowplayer')
    const getReferrerURL = () => {
      const embedLocation = window.top == window ? window.location.href : document.referrer
      const link = document.createElement('a')
      link.href = embedLocation

      return `${link.protocol}//${link.hostname}`
    }

    const baseOptions = {
      'content.id': this._player.config.video_id,
      'content.isLive': _get(this, '_player.config.video_type') === VIDEO_TYPE.LIVE,
      'content.title': this._player.config.title,
      'content.genre': this._player.config.genre || '',
      'content.customDimension.1': getReferrerURL(),
      'content.customDimension.2': `${this._player.config.title} (${this._player.config.gsc_id})`,
    }

    if (_get(this, '_player.player.hls.url')) {
      baseOptions['content.resource'] = this._player.player.hls.url
    }

    if (typeof _get(this, '_player.player.dash.getSource') === 'function') {
      baseOptions['content.resource'] = this._player.player.dash.getSource()
    }

    plugin.setOptions(baseOptions)

    fcLogger.log('options were sent to youbora', baseOptions)

    plugin.setAdapter(new youbora.adapters.Flowplayer(this._player.player))

    this._player.on(CHAPTER_EVENTS.USER_ENTER, fireEvent)
    this._player.on(CHAPTER_EVENTS.USER_LEAVE, fireEvent)
    this._player.on(CHAPTER_EVENTS.USER_MARK, fireEvent)
  }
}
