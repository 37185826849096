/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import {FlowPlayerFeatureBase} from '../FlowPlayerFeatureBase'
import {createHtmlElementFromString} from '../../../lib/Helper'
import {fcLogger} from '../../../lib/FcLogger'
import moment from 'moment-timezone'
import _get from 'lodash/get'
import _isUndefined from 'lodash/isUndefined'
import {VIDEO_TYPE} from '../../VideoTypes'

export class AbsoluteTimeFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor(player) {
    super(player)

    this.setupFeature()
  }

  reload (config) {
    this.setupFeature()
  }

  getTimestampEl() {
    let timestampEl = this._player.container.querySelector('.fc-absolute-time')

    return timestampEl
      ? timestampEl
      : createHtmlElementFromString(`<div class="fc-absolute-time"></div>`)
  }

  setupFeature() {
    if (!_get(this, '_player.player.fas.current_timecode'))
      return fcLogger.warn('Fas plugin is not included')

    this.videoTimezone = _get(this, '_player.config.timezone')
      ? this._player.config.timezone
      : 'Europe/Brussels'

    this.timecodeInterval = null

    this.timestampEl = this.getTimestampEl()

    // TODO: make this configurable
    this._player.player.on('loadeddata', this.enableOverlay.bind(this))

    if (_isUndefined(this._player.currentTimestamp))
      Object.defineProperty(this._player, 'currentTimestamp', { get: () => this.getCurrentTimestamp() })

    if (_isUndefined(this._player.edgeTimestamp))
      Object.defineProperty(this._player, 'edgeTimestamp', { get: () => this.getEdgeTimestamp() })

    if (_isUndefined(this._player.currentTimecode))
      Object.defineProperty(this._player, 'currentTimecode', { get: () => this.getCurrentTimecode() })
  }

  getCurrentTimecode() {
    return this._player.player.fas.current_timecode()
  }

  getCurrentTimestamp() {
    if (this._player.config.live === true)
      return Number.parseInt(this.getCurrentLIVETimestamp()) ? Number.parseInt(this.getCurrentLIVETimestamp()) : null

    return Number.parseInt(this.getCurrentVODTimestamp()) ? Number.parseInt(this.getCurrentVODTimestamp()) : null
  }

  getEdgeTimestamp() {
    if (!_get(this, '_player.player.hls.streamController.fragPlaying.sn')) return null

    try {
      const getRemainingDuration = (fragSn) => {
        return this._player.player.hls.levels[this._player.player.hls.currentLevel].details.fragments
          .filter(frag => frag.sn > fragSn)
          .map(frag => frag.duration)
          .reduce((sum, duration) => sum + duration)
      }

      if (!this.edgeTimestamp) {
        this.edgeTimestamp = {
          markedAt: moment(),
          timestamp: this._player.currentTimestamp + (getRemainingDuration(this._player.player.hls.streamController.fragPlaying.sn) * 1000)
        }
      }

      return this.edgeTimestamp.timestamp + moment().diff(this.edgeTimestamp.markedAt)
    } catch (e) {
      fcLogger.warn('Could not compute edge timestamp', e)
      return null
    }
  }

  getCurrentLIVETimestamp() {
    try {
      const timestamp = (
        this._player.player.hls.streamController.fragPlaying.programDateTime
        + (this._player.currentTime - this._player.player.hls.streamController.fragPlaying.startPTS)
        * 1000
      )

      return timestamp
    } catch (e) {
      // Fix Mobile/IOS not being able to fetch currentTimestamp
      if (typeof this.videoElement.getStartDate === 'function') {
        return Number.parseInt(moment(this.videoElement.getStartDate().getTime()).format('x')) + this._player.currentTime * 1000
      }

      return null
    }
  }

  getCurrentVODTimestamp() {
    const videoType = _get(this, '_player.config.video_type')

    if (!this._player.config.start) {
      fcLogger.warn('Cannot generate absolute time for VOD: "start" missing from config')
      return null
    }

    switch (videoType) {
      // we cannot compute post-prod real timestamps
      case VIDEO_TYPE.VOD:
      case VIDEO_TYPE.CLIP:
        if (_get(this, '_player.config.clip_sections')) {
          return this._player.fc_features.get('ClipSectionFeature').getAbsoluteTime(this._player.currentTime)
        }
        this.disableOverlay()
        return null
      case VIDEO_TYPE.REPLAY:
      default:
        return (this._player.currentTime * 1000) + this._player.config.start
    }
  }

  enableOverlay() {
    const previousIcon = _get(this, '_player.config.live') === true
      ? 'live'
      : 'rewind'

    this._player.insertIcon(this.timestampEl, {after: previousIcon })

    this.timecodeInterval = setInterval(() => {
      const datetime = this.getDateTime()
      this.timestampEl.innerText = datetime ? datetime : ''
    }, 500)
  }

  disableOverlay() {
    this.timestampEl.remove()
    clearInterval(this.timecodeInterval)
  }

  getDateTime() {
    if (_get(this, '_player.config.live') === true)
      return this.getLiveDatetime()

    return this.getVODDatetime()
  }

  getVODDatetime() {
    const ts = this.getCurrentVODTimestamp()

    if (ts)
      return this.formatDatetime(moment(ts))

    return null
  }

  getLiveDatetime() {
    if (!_get(this, '_player.player.hls')) return this.disableOverlay()
    return this.formatDatetime(moment(this._player.currentTimestamp))
  }

  formatDatetime(momentInstance) {
    const format = (_get(this, '_player.config.fcBackoffice') === true) && _get(this, '_player.config.live') === true
      ? 'HH:mm:ss'
      : 'HH:mm'

    const formatted = momentInstance.tz(this.videoTimezone)

    return formatted.isValid() ? formatted.format(format) : null
  }

  getName() {
    return 'AbsoluteTimeFlowPlayerFeature'
  }
}
