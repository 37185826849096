/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 * @see https://developers.atinternet-solutions.com/javascript-en/getting-started-javascript-en/tracker-initialisation-javascript-en/ (smarttag doc)
 */

import {fcLogger} from '../../../../lib/FcLogger'
import {loadJsFiles} from '../../../../lib/Helper'
import {BaseVideoEvents} from '../../../baseVideoEvents'

export class ATInternetHelper {
  constructor (player, config) {

    this.config = {
      kentryid: '0_uaufthbm',
      kuiconfid: '30021082',
      kwidgetid: '_3000261',
      kpartnerid: '3000261',
    }

    if (!config.script_url) {
      // if this occurs, check .env
      return fcLogger.warn('ATInternet: script_url missing.')
    }

    this.files = [config.script_url]

    this.tag = null
    this.player = player

    // Since the concilium page already have setup ATInternet,
    // we only handle it when not loaded. (i.e. external website embeds)

    if (window.ATInternet) {
      fcLogger.warn('ATInternet already loaded.')
      return null
    }

    this.load().then(() => {
      fcLogger.info('ATInternet loaded')
    })
  }

  async load () {
    // Load files
    await loadJsFiles(this.files, this.player.ownerDocument)

    if (!window.ATInternet)
      return fcLogger.warn('ATInternet instance not available')

    // Init tag object
    this.handleVideoSpecifics()
    this.initTagObject()
    this.watchPlayerEvents()
  }

  initTagObject () {
    this.tag = new ATInternet.Tracker.Tag({
      secure: location.protocol === 'https:',
      ignoreEmptyChapterValue: true,
    })

    this.tag.page.set({
      name: getLastURLSegment(location.href),  /* to be replaced - current url's last segment*/
      level2: '20', /* not to be replaced - maps to Council live */
      chapter1: this.player.config.title,  /* to be replaced - title of page rendering in h1 tag */
    })

    this.tag.customVars.set({
      site: {
        10: encodeURIComponent(location.href),
      },
    })

    this.tag.dispatch()

    fcLogger.info('ATInternet tag object initialized')
  }

  watchPlayerEvents () {
    const sendVideoInfo = (event) => {
      const action = event.type
      let buffering = false

      let richMediaInfo = {
        action: action,
        playerId: this.player.config.video_id,
        mediaLabel: getMediaLabel(this.player),
        duration: this.player.duration.toFixed(),
      }

      // isBuffering property will only be changed in case of waiting and progress events
      // combined with seeking actions
      if (action === 'progress' || action === 'waiting') {
        richMediaInfo.action = 'info'

        if (buffering && action === 'progress' && !this.player.seeking) {
          buffering = false
        } else if (action === 'waiting' && buffering === false) {
          buffering = true
        } else {
          // do not send info object
          return
        }

        richMediaInfo.isBuffering = Number(buffering)
      }

      // Do not send any information while buffering or seeking
      if ((buffering || (this.player.seeking)) && richMediaInfo.action !==
        'info') {
        return
      }

      this.tag.richMedia.send(richMediaInfo)
    }
    const addVideoInfo = (event) => {
      const richMediaInfo = {
        mediaType: 'video',
        playerId: this.player.config.video_id,
        mediaLevel2: '20',
        mediaLabel: getMediaLabel(this.player),
        isEmbedded: true,
        broadcastMode: 'clip',
        duration: this.player.duration.toFixed(),
      }

      this.tag.richMedia.add(richMediaInfo)
    }

    this.player.player.on('loadedmetadata', addVideoInfo.bind(this))

    const excludedEvents = [
      BaseVideoEvents.TIMEUPDATE,
      BaseVideoEvents.CANPLAY,
      BaseVideoEvents.CANPLAYTHROUGH
    ]

    this.player.player.on(
      Object.values(BaseVideoEvents)
        .filter(event => !excludedEvents.includes(event))
        .join(' '),
      sendVideoInfo.bind(this)
    )
  }

  handleVideoSpecifics () {
    const videoElement = this.player.container.querySelector('video')

    videoElement.setAttribute('kentryid', this.config.kentryid)
    videoElement.setAttribute('kuiconfid', this.config.kuiconfid)
    videoElement.setAttribute('kwidgetid', this.config.kwidgetid)
    videoElement.setAttribute('kpartnerid', this.config.kpartnerid)

    videoElement.classList.add('persistentNativePlayer')
    videoElement.classList.add('nativeEmbedPlayerPid')
  }
}

const getLastURLSegment = (url) => {
  // remove tailing slash then take the last segment
  return url.replace(/\/+$/, '').split('/').pop()
}

const getMediaLabel = (player) => {
  const externalDomain = window.location !== window.parent.location
    ? document.referrer
    : ''
  //#video_category#::#video_subcategory#::#external_domain#::#video_name#
  return ('embed' + '::::' + encodeURIComponent(externalDomain) + '::' +
    player.config.title)
}
