/**
 * @package   freecaster/luna/player
 * @author    Yannick Delwiche <yannick.delwiche@freecaster.com>
 * @copyright Freecaster 2019
 */

export const getCurrentScript = () => {
  if (document.currentScript) {
    return document.currentScript
  }

  // IE 11 polyfill
  let script = null
  Array.from(document.querySelectorAll('script')).forEach(function (element) {
    if (element.src.match(/embedder.js/))
      script = element
  })

  return script
}
