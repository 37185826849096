/**
 * @package   freecaster/luna/player
 * @author    Yannick Delwiche <yannick.delwiche@freecaster.com>
 * @copyright Freecaster 2019
 */
import MessageSender from './MessageSender'

export default class ProxyClient extends MessageSender {
  constructor (event) {
    super(event.ports[0]);

    this.video = this.createVideoElement()

    console.debug('Parent notified')
  }

  createVideoElement () {
    let video = document.createElement('video')

    return new Proxy(video, {
      get: this.getProperty.bind(this),
      set: this.setProperty.bind(this),
    })
  }

  callMethod (object, method) {
    console.debug('Calling ' + method)

    let _this = this;

    return async function (...args) {
      console.debug(args)

      return _this.waitForReply(_this.sendMessage('call:' + method, args))
    }
  }

  getProperty (object, property) {
    if (typeof object[property] === 'function') {
      return this.callMethod(object, property)
    }
    console.debug('Getting ' + property)

    return this.waitForReply(this.sendMessage('get:' + property))
  }

  setProperty (object, property, value) {
    console.debug('Setting ' + property + ' to ' + value)

    return this.sendMessage('set:' + property, value)
  }

  getVideoElement () {
    return this.video
  }
}
