import _get from 'lodash/get'
import { PlayerFeatureBase } from '../PlayerFeatureBase'

/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

export class PreviewGenericFeature extends PlayerFeatureBase {
  constructor (playerInstance) {
    super(playerInstance)
    this._player = playerInstance

    playerInstance.on('fcPreviewPlayer', this.onPreviewPlayer.bind(this))
    playerInstance.on('fcRemovePreviewPlayer', this.onRemovePreviewPlayer.bind(this))

    this.setInitialPreviewMode()
  }

  getName() {
    return 'PreviewGenericFeature'
  }

  setInitialPreviewMode() {
    if (!!_get(this, '_player.currentConfig.fc_preview')) {
      this.onPreviewPlayer()
    }
  }

  onPreviewPlayer() {
    this._player.config.preview_mode = true
  }

  onRemovePreviewPlayer() {
    this._player.config.preview_mode = false
  }
}

