/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import moment from 'moment-timezone'
import _get from 'lodash/get'

import {createHtmlElementFromString} from '../../../lib/Helper'
import {FlowPlayerFeatureBase} from '../FlowPlayerFeatureBase'

export class HoverTimelineTimeFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor(player) {
    super(player)

    this.timeFormats = {
      vod: 'HH:mm:ss',
      live: 'HH:mm',
      /**
       * @param isLive {boolean}
       * @return {string}
       */
      get(isLive) {
        return isLive ? this.live : this.vod
      },
    }

    this.observer = null

    this.mousePosition = { x: 0, y: 0 }
    this._player.container.addEventListener('mousemove', (event) => {
      this.mousePosition = { x: event.clientX, y: event.clientY }
    })
  }

  getName() {
    return 'HoverTimelineTimeFlowPlayerFeature'
  }

  reload () {
   this.handle()
  }

  handle() {
    this.videoTimezone = this._player.fc_features.get('AbsoluteTimeFlowPlayerFeature').videoTimezone

    this.timeHelper = {
      snapshot: null,
      snapFrag: null,
      diffFromNowMs() {
        if (this.snapshot instanceof moment) {
          return moment().diff(this.snapshot)
        }

        return null
      },
      getCurrentTs() {
        if (this.snapshot instanceof moment && this.snapFrag instanceof moment) {
          // we clone the snapFrag moment instance to avoid adding onto it
          return parseInt(this.snapFrag.clone().add(this.diffFromNowMs(), 'ms').format('x'))
        }

        return null
      }
    }

    if (_get(this, '_player.player.hls')) {
      this._player.player.hls.on('hlsFragLoaded', () => {
        if (this.timeHelper.snapshot && this.timeHelper.snapFrag) return
        if (!Array.isArray(_get(this, '_player.player.hls.levels[0].details.fragments'))) return
        const fragments = this._player.player.hls.levels[0].details.fragments
        if (typeof fragments[fragments.length-1].programDateTime !== 'number') return
        this.timeHelper.snapshot = moment()
        this.timeHelper.snapFrag = moment(fragments[fragments.length-1].programDateTime)
      })
    }

    this.replaceTimelineTime()
  }

  replaceTimelineTime() {
    const fpTimestampEl = this.getFpTimestampEl()

    if (!fpTimestampEl) return
    if (this.observer) this.observer.disconnect()

    this.observer = new MutationObserver(((mutations, observer) => {
      const fpTimestamp = mutations.map(m => m.target).pop()
      if (fpTimestamp) this.renderTime(fpTimestamp)
    }))

    this.observer.observe(fpTimestampEl, { attributes: false, childList: true, subtree: false })
  }

  getStartOffset() {
    // LIVE
    if (_get(this, '_player.config.live') === true) {
      return this.timeHelper.getCurrentTs()
    }

    // VOD
    return typeof _get(this, '_player.config.start') === 'number'
      ? this._player.config.start
      : null
  }

  renderTime(fpTimestampEl) {
    const getFcTimelineTimeEl = () => {
      let fcTimelineTimeEl = this._player.container.querySelector('.fc-timeline-time')

      if (!fcTimelineTimeEl) {
        fcTimelineTimeEl = createHtmlElementFromString(`<div class="fc-timeline-time"></div>`)
        fpTimestampEl.insertAdjacentElement('afterend', fcTimelineTimeEl)
      }

      return fcTimelineTimeEl
    }
    /**
     *
     * @param rawDiff: string - 00:00 / 00:00:00
     * @return {{hours: number, seconds: number, minutes: number, operator: (string)}}
     */
    const getTimeDiffInfo = (rawDiff) => {
      const timeArray = rawDiff.split(':')

      let hours = 0
      let minutes = 0
      let seconds = 0

      if (timeArray.length === 2) {
        minutes = Math.abs(parseInt(timeArray[0]))
        seconds = parseInt(timeArray[1])
      }

      if (timeArray.length === 3) {
        hours = Math.abs(parseInt(timeArray[0]))
        minutes = parseInt(timeArray[1])
        seconds = parseInt(timeArray[2])
      }

      const operator = timeArray[0].indexOf('-') > -1
        ? 'subtract'
        : 'add'

      return { operator, hours, minutes, seconds }
    }
    const updateFcTimelineTime = (timeToRender) => {
      const element = getFcTimelineTimeEl()

      const containerRect = this._player.container.getBoundingClientRect()

      element.innerHTML = timeToRender
      element.style.left = `${this.mousePosition.x - containerRect.left}px`
      element.style.marginLeft = `-${element.clientWidth / 2}px`
    }
    const getTextToParse = () => {
      return fpTimestampEl.querySelector('span')
        ? fpTimestampEl.querySelector('span').innerText
        : fpTimestampEl.innerText
    }
    const getTimestamp = (diffInfo) => {
      if (Array.isArray(_get(this, '_player.config.clip_sections')) && this._player.config.clip_sections.length > 0) {
        // in case of edited VODs we need to compute the currentTime
        const currentTime =
          diffInfo.hours * 3600 + diffInfo.minutes * 60 + diffInfo.seconds

        return this._player.fc_features
        .get('ClipSectionFeature')
        .getAbsoluteTime(currentTime)
      }

      return parseInt(
        moment(this.getStartOffset())
          [diffInfo.operator](diffInfo.hours, 'hours')
          [diffInfo.operator](diffInfo.minutes, 'minutes')
          [diffInfo.operator](diffInfo.seconds, 'seconds')
        .format('x')
      )
    }
    const canDisplayHoverTimeline = () => {
      // cannot compute without a startoffset unless we are live
      return this._player.config.live || (!this._player.config.live && !!this.getStartOffset())
    }

    if (!canDisplayHoverTimeline()) {
      const fcTimelineTimeEl = getFcTimelineTimeEl()
      if (fcTimelineTimeEl) fcTimelineTimeEl.remove()

      return
    }

    const textToParse = getTextToParse()
    const diffInfo = getTimeDiffInfo(textToParse)
    const format = this.timeFormats.get(this._player.config.live)
    const timestamp = getTimestamp(diffInfo)

    const time = moment(timestamp)
    const timeToRender = time.isValid()
      ? time.tz(this.videoTimezone).format(format)
      : ''

    updateFcTimelineTime(timeToRender)
  }

  getFpTimestampEl() {
    return this._player.container.querySelector('.fp-timestamp')
  }
}
