/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import FlowPlayer from './FlowPlayer/FlowPlayer'
import { fcLogger } from '../lib/FcLogger'

class PlayerFactory {
  /**
   *
   * @param {String} element - the element the player is attached to (ie '#video-container')
   * @param {Object} player - the configuration object (see Player.js)
   * @param {String }type - the player you want an instance of (ie. 'Flow')
   * @returns * - an instance of the desired player
   */
  create({ element, data, type }) {

    switch(type) {
      case 'Flow':
        return new FlowPlayer(fcEmbedder, element, data)
      case 'JW7':
        fcLogger.warn('JW7 is not implemented')
        break;
      default:
        fcLogger.warn(`Player not supported ${type}`)
    }

  }
}

export const playerFactory = new PlayerFactory()
