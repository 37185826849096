/**
 * @package freecaster/luna/player
 * @author Daniel Vos <daniel.vos@freecaster.com>
 * @author Maxime Bartier <maxime.bartier@freecaster.com>
 * @copyright Freecaster 2019
 */

import { FlowPlayerFeatureBase } from '../FlowPlayerFeatureBase'
import _get from 'lodash/get'
import {createHtmlElementFromString} from '../../../lib/Helper'

/**
 * Structure in config asked
 * this._pllayer.config.watermark
 * @link String {link for redirection}
 * @img String {image source}
 * @style Object {other style like (top: 0; left: 0;)}
 */

export class WatermarkFlowPlayerFeature extends FlowPlayerFeatureBase {

  constructor (player) {
    super(player)

    if (!_get(this, '_player.config.watermark')) return
    if (_get(this, '_player.config._dw') === true) return

    this.watermark = {
      img: '',
      styles: [],
      link: '',
      ...this._player.config.watermark,
    }

    if (this.watermark.img) {
      this.createWatermark()
    }
  }

  getName() {
    return 'WatermarkFlowPlayerFeature'
  }

  removeButton () {
    const watermark = this._player.container.querySelector('.fc-watermark')
    if (watermark) watermark.remove()
  }

  createWatermark () {
    const watermark = createHtmlElementFromString(`<img class="fc-watermark" src=${this.watermark.img} alt="logo" />`)
    this.container.appendChild(watermark)
    this.catchEvents(watermark)
  }

  catchEvents (watermark) {
    watermark.addEventListener('click',
      () => window.open(this.watermark.link, '_blank'))
  }

  /**
   * helper watermark style construct
   * @return {string}
   */
  styleConstruct () {
    const props = []

    props.push(`background-image:url(${this.watermark.img})`)

    Object.keys(this.watermark.styles).forEach((key) => {
      props.push(`${key}: ${this.watermark.styles[key]}`)
    })

    return props.join(';')
  }

}
