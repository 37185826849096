/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import { FlowPlayerFeatureBase } from '../../FlowPlayerFeatureBase'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import { createHtmlElementFromString } from '../../../../lib/Helper'
import { LockFlags } from '../../../../Player'
import { fcLogger } from '../../../../lib/FcLogger'
import {playerDisplayTextPositionEnum} from './PlayerDisplayTextPositionEnum'

export class PlayerDisplayFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor (player) {
    super(player)

    this._player.on('fcPreviewPlayer', this.onPreviewPlayer.bind(this))
    this._player.on('fcRemovePreviewPlayer', this.onRemovePreviewPlayer.bind(this))

    // stored function into a variable in order to be able to remove
    // the event listener applied to the window Object
    this.fontSizeHandler = this.handleFontSize.bind(this)

    if (_get(this, '_player.currentConfig.player_display')) {

      this.mediaInfo = this.getMediaInfo(this._player.currentConfig.player_display.background_urls)

      if (!this.mediaInfo) {
        return fcLogger.warn('Unrecognize file extension')
      }

      this.add(this._player.currentConfig.player_display)
    }

    if (this._player.currentConfig.preview_mode) this.onPreviewPlayer()
  }

  getName() {
    return 'PlayerDisplayFlowPlayerFeature'
  }

  onPreviewPlayer() {
    this.remove()
  }

  onRemovePreviewPlayer() {
    if (_get(this, '_player.currentConfig.player_display')) {
      this.add(this._player.currentConfig.player_display)
    }
  }

  handleFontSize() {
    const baseValue = {
      width: 640,
      fontSize: 12
    }

    const playerWidth = this._player.container.innerWidth()
    const widthPercentage = (playerWidth / baseValue.width) * 100
    const computedFontSize = Math.ceil((baseValue.fontSize * widthPercentage) / 100)

    const element = this.getElement()

    if (element && computedFontSize > 0)
      element.style.fontSize = `${computedFontSize}px`
  }

  /**
   * Return an object containing the information of the media to be
   * injected as background
   * @param urls
   * @returns {{format: string, type: string, url: string}|boolean}
   */
  getMediaInfo (urls) {
    if (!urls) return false

    const url = this.getLocalizedUrl(urls)

    if (typeof url === 'string' && url.match(/\./)) {

      const format = url.substr(url.lastIndexOf('.') + 1)

      if (format.match(/(mp4|webm|ogg)/ig)) {
        return {type: 'video', format, url}
      }

      if (format.match(/(png|jpg|jpeg|bmp|jfif|pjp|pjpeg)/ig)) {
        return {type: 'image', format, url}
      }
    }

    return false
  }

  addOrUpdate (value) {
    if (this._player.currentConfig.preview_mode) return

    // Refresh the type of media in case of switch between video & image
    this.mediaInfo = this.getMediaInfo(value.background_urls)

    if (this.getElement())
      this.update(value)

    this.add(value)
  }

  update (value) {
    if (!this.getElement()) return

    const element = this.createElement({
      background_url: this.mediaInfo.url,
      message: value.message,
      name: value.name,
      text_position: value.text_position
    })

    this.getElement().replaceWith(element)
  }

  add (value) {
    if (this.getElement() || this._player.currentConfig.preview_mode) return

    value = {
      background_url: '',
      name: '',
      message: '',
      ...value,
    }

    const fcDisplayEl = this.createElement(value)
    this.container.insertAdjacentElement('afterbegin', fcDisplayEl)

    // lock fn comes from anyplayer
    this._player.lock(LockFlags.LOCK_COUNTDOWN)
    this._player.muted = true

    this.handleFontSize()
    window.addEventListener('resize', this.fontSizeHandler, true)
  }

  remove () {
    const fcDisplayEl = this.getElement()

    if (fcDisplayEl) {
      window.removeEventListener('resize', this.fontSizeHandler, true)

      fcDisplayEl.remove()

      // unlock fn comes from anyplayer
      this._player.unlock(LockFlags.LOCK_COUNTDOWN)
      this.goBackToLive()
      this._player.play()
    }
  }

  getElement () {
    return this.container.querySelector('.fc-display')
  }

  createElement ({background_url, header, message, footer, text_position}) {

    const position = playerDisplayTextPositionEnum.has(text_position)
      ? text_position
      : playerDisplayTextPositionEnum.MIDDLE_CENTER

    const media = this.getMediaElement(background_url)
      let el = createHtmlElementFromString(`
      <div class="fc-display">
        <div class="fc-display__content">
            ${header || message || footer ? `<div class="fc-display__banner"></div>` : ''}
            <div class="fc-display__text fc-display__text--${position}">
              ${header ? `<span class="fc-display__text--header">${header || ''}</span>` : ''}
              ${message ? `<span class="fc-display__text--message">${message || ''}</span>` : ''}
              ${footer ? `<span class="fc-display__text--footer">${footer || ''}</span>` : ''}
            </div>
        </div>
      </div>
     `)

      if (media) el.appendChild(media)

    return el
  }

  getMediaElement () {
    switch (this.mediaInfo.type) {
      case 'video':
        return this.getVideoElement()
      case 'image':
        return this.getImageElement()
      default:
        return false
    }
  }

  getImageElement () {
    return createHtmlElementFromString(
      `<div class="fc-display__image" style="background-image:url(${this.mediaInfo.url})"></div>`)
  }

  getVideoElement () {
    return createHtmlElementFromString(`
        <video class="fc-display__video" width="100%" height="100%" muted autoplay loop>
            <source src="${this.mediaInfo.url}" type="video/${this.mediaInfo.format}">
        </video>
        `)
  }

  getLocalizedUrl (urls) {
    if (typeof this._player.currentConfig.locale === 'string') {
      const locale = this._player.currentConfig.locale.toLowerCase()

      // Urls contains a property that matches the attributes "locale"
      if (urls.hasOwnProperty(locale))
        return urls[locale]

      // we try to match the lang part with the urls object
      if (locale.match(/^([a-z]{2}-[a-z]{2})$/)) {
        const lang = locale.split('-')[0]

        const foundLang = Object.keys(_omit(urls, 'default')).find(u => u.substring(0, 2).match(lang))

        if (foundLang)
          return urls[foundLang]
      } else {
        fcLogger.warn(`Locale attribute '${this._player.currentConfig.locale}' is not well formatted. Expecting: 'll-cc' (ie. fr-be)`)
      }
    }

    // No attribute "locale" is used or any match with the lang > return default url
    return urls.default
  }
}
