/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

export class PlayerProperty {
  constructor () {
    if (this.constructor === PlayerProperty) {
      throw new Error('Abstract class cannot be instantiated directly.')
    }

    if (this.handle === undefined) {
      throw new Error('Properties must implement the handle method')
    }

    if (this.getName === undefined) {
      throw new Error('Properties must implement the getName method')
    }
  }
}
