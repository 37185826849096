/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import _get from 'lodash/get'
import {PlayerFeatureBase} from '../../PlayerFeatureBase'
import {ClipSections} from './ClipSections.class'

export class ClipSectionFeature extends PlayerFeatureBase {
  constructor (player) {
    super(player)

    this.clipSections = {}

    this.setSections(this._player.config)
  }

  setSections (config) {
    if (
      _get(config, 'clip_sections') &&
      _get(config, 'start')) {
      this.clipSections = new ClipSections(config.clip_sections, config.start)
    } else {
      this.clipSections = {}
    }
  }

  hasSections() {
    return Array.isArray(_get(this, 'clipSections.sections')) && this.clipSections.sections.length > 0
  }

  reload (config) {
    this.setSections(config)
  }

  getName () {
    return 'ClipSectionFeature'
  }

  /**
   *
   * @param currentTime {number} - current video time in seconds
   * @return {number | null}
   */
  getAbsoluteTime (currentTime) {
    if (this.clipSections instanceof ClipSections)
      return this.clipSections.getTimestamp(currentTime)

    return null
  }
}

