/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import { FlowPlayerFeatureBase } from '../FlowPlayerFeatureBase'
import { createHtmlElementFromString } from '../../../lib/Helper'

export class RewindFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor (player) {
    super(player)

    this._player.player.on('loadedmetadata', this.onLoadedMetadata.bind(this))
  }

  getName() {
    return 'RewindFlowPlayerFeature'
  }

  getElement() {
    return this._player.container.querySelector('.fc-icon-rewind')
  }

  onLoadedMetadata(event) {
    // we only "really" know if we are live when video metadata are loaded
    if (!this._player.currentConfig.live) {
      this.addButton()
    }
  }

  addButton () {
    if(this.getElement()) return

    // create rewind icon
    this.icon = this.createRewindIcon()

    // insert it into player
    this._player.insertIcon(this.icon, {after: 'play'})

    // register icon events
    this.registerIconEvents()
  }

  removeButton () {
    const btn = this.getElement();

    if (btn)
      btn.remove()

  }

  createRewindIcon () {
    return createHtmlElementFromString(`
    <div class="fc-icon-rewind" 
         tabindex="0" 
         role="button" 
         aria-label="Rewind" 
         aria-expanded="false" 
         aria-haspopup="true">
        <span></span>
    </div>
    `)
  }

  registerIconEvents () {
    this.icon.addEventListener('click', this.rewind.bind(this))
    this.icon.addEventListener('touchstart', this.rewind.bind(this))
    this.icon.addEventListener('keydown', this.keyHandler.bind(this))
    this.icon.addEventListener('pointerdown', this.keyHandler.bind(this))
  }

  keyHandler (e) {
    // 32 (space), 13 (enter)
    if (e.which === 32 || e.which === 13) {
      e.stopPropagation()
      e.preventDefault()
      this.rewind()
    }
  }

  rewind () {
    this._player.currentTime = Math.max(this._player.currentTime - 10, 0)
  }
}
