import { fetch as fetchPolyfill } from 'whatwg-fetch'

export function polyfills () {
  if (typeof window.fetch === 'undefined') {
    window.fetch = fetchPolyfill
  }

  if (!('remove' in Element.prototype)) {
    Element.prototype.remove = function() {
      if (this.parentNode) {
        this.parentNode.removeChild(this);
      }
    };
  }
}

