/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import _transform from 'lodash/transform'
import _isEqual from 'lodash/isEqual'
import _isObject from 'lodash/isObject'
import { languageCodes } from "./languageCodes";
import _get from 'lodash/get'
import { fcLogger } from './FcLogger'

/**
 * Deep diff between two object, using lodash
 * https://gist.github.com/Yimiprod/7ee176597fef230d1451
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  function changes(object, base) {
    return _transform(object, function(result, value, key) {
      if (!_isEqual(value, base[key])) {
        result[key] =
          _isObject(value) && _isObject(base[key])
            ? changes(value, base[key])
            : value
      }
    })
  }

  return changes(object, base)
}

/**
 * Convert the value into number or bool if applicable or return the original
 * value
 * @param value
 * @returns {boolean}
 */
export function cast (value) {
  if (typeof value === 'string') {
    if (value.match(/^[0-9]+(\.[0-9]+)?$/)) {
      value = parseFloat(value)
    } else if (value.match(/^(true|1|yes|on)$/i)) {
      value = true
    } else if (value.match(/^(false|0|no|off)$/i)) {
      value = false
    }
  }

  return value
}

/**
 * Convert a value to a valid Css dimension.
 * Returns null if value is not valid
 * @param {string|number} value - the value to be converted into CSS dimension
 * @returns {string|null}
 */
export function toCssDimensionValue (value) {
  if (typeof value === 'string' && value.match(/\d+%$/)) return value
  else if (typeof value === 'string' && value.match(/\d+px$/)) return value
  else if (typeof value === 'number' || typeof value === 'string' && value.match(/\d+$/)) return value + 'px'

  fcLogger.warn('Could not convert value to a valid CSS dimension', value)
  return null
}

/**
 * @param {string} html - the html string to be converted
 * @returns {ChildNode}
 */
export function createHtmlElementFromString (html) {
  if (typeof html !== 'string') {
    fcLogger.warn('Could not create element from type ' + typeof html)
    return null
  }

  const template = document.createElement('template')
  template.innerHTML = html.trim()

  return !!template.content ? template.content.firstChild : template.childNodes[0]
}

export function getPercentageFromNumber (number, total) {
  return (number / total) * 100
}

export function getLanguagesText (locale) {
  if (!locale) return fcLogger.warn('unknown language')

  const foundLanguage = languageCodes.find(language => language.code === locale)
  return foundLanguage ? foundLanguage.name : locale
}

export function getUrlParams(search) {
  const hashes = search.slice(search.indexOf('?') + 1).split('&').filter(c => c.length > 0)
  const params = {}
  hashes.map(hash => {
    const [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })

  Object.keys(params).forEach(key => params[key] = cast(params[key]))

  return params
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n)

/**
 * Recursive function that load all scripts files contained the provided array.
 * @param {Array<string>|Array<{url: string, async: boolean}>} files
 * @param {Document} document? - document to insert script files, will default to window.document
 * @param {boolean} isAsync
 * @return {Promise<any>}
 */
export function loadJsFiles(files, document = window.document, isAsync = false) {
  const isFileLoaded = fileUrl => {
    const loadedFiles = Array.from(document.head.getElementsByTagName('script')).map(el => el.src)
    return !!loadedFiles.find(scriptUrl => scriptUrl.includes(fileUrl))
  }

  files = JSON.parse(JSON.stringify(files))

  return new Promise(resolve => {
    if (files.length === 0) return resolve()

    const url = files.shift()

    // if the file is already loaded, we skip it
    if (isFileLoaded(url)) return resolve(files, document)

    const element = createScriptEl({url, async: isAsync})

    document.head.appendChild(element)
    element.onload = element.onerror = () => resolve(loadJsFiles(files, document))
  })
}

/**
 * Create a script element (script)
 * @param url
 * @param async
 * @returns {HTMLScriptElement}
 */
export function createScriptEl({url, async = false}) {
  const element = document.createElement('script')
  element.setAttribute('type', 'text/javascript')
  element.setAttribute('src', url)

  if (async) element.async = true

  return element
}
