/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import {PlayerProperty} from '../PlayerProperty'

class VttUrlProperty extends PlayerProperty {
  /**
   *
   * @param player
   * @param {ServerEvent} value
   * @return {Promise<void>}
   */
  async handle(player, value) {
    const thumbnailFeature = player.fc_features.get('ThumbnailsFlowPlayerFeature')

    thumbnailFeature.setThumbnails(value.target.attributes)
  }

  getName () {
    return 'vtt_url'
  }
}

export const vttUrlProperty = new VttUrlProperty()
