/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import { PlayerProperty } from '../PlayerProperty'

class DvrProperty extends PlayerProperty {
  handle (player, value) {
    const dvrFlowPlayerFeature = player.fc_features.get('DvrFlowPlayerFeature')

    dvrFlowPlayerFeature.setDvr(value)
  }

  getName () {
    return 'dvr'
  }
}

export const dvrProperty = new DvrProperty()
