/**
 * @package   freecaster/luna/player
 * @author    Yannick Delwiche <yannick.delwiche@freecaster.com>
 * @copyright Freecaster 2019
 */

import youbora from 'youboralib'
import _get from 'lodash/get'
import Player from '../../Player'
import { cast, toCssDimensionValue } from '../../lib/Helper'
import { FlowPlayerFeatures } from './FlowPlayerFeatures'
import { flowLegacyService } from './legacy/FlowLegacyService'

export default class FlowPlayer extends Player {
  setupPlayer () {
    // We must cast strings into the corresponding types (bool, int, ...) in order to make
    // properties like "autoplay" or "muted" to work with flowplayer
    Object.keys(this.player.setup).forEach(key => this.player.setup[key] = cast(this.player.setup[key]))

    let setup = {
      ...this.player.setup || {}, ...this.video, ...this.getElementConfig(),
    }

    // Width and height must be applied to the container
    const width = toCssDimensionValue(setup.width)
    if (width) {
      this.element.style.width = width
      delete setup.width
    }

    const height = toCssDimensionValue(setup.height)
    if (height) {
      this.element.style.height = height
      delete setup.height
    }


    if (_get(setup, 'all_ordered_languages')) {
      this.sortAudioTracks(setup, Object.keys(setup.all_ordered_languages))
    }

    if (_get(setup, 'trackers.youbora.account_code'))
      this.setupYouboraPlugin(setup.trackers.youbora.account_code)

    /**
     * Force HLS to start with the highest GSC Quality
     */
    setup.hls = {
      startLevel: 3
    }

    // noinspection JSUnresolvedFunction
    return flowplayer(this.element, setup)
  }

  setupYouboraPlugin(accountCode) {
    window.plugin = new youbora.Plugin({ accountCode })
  }

  sortAudioTracks(setup, languageOrder = []) {
    if (!Array.isArray(languageOrder)) return

    Object.assign(setup, {asel: { sort: sortByLang(languageOrder)}})

    function sortByLang(order) {
      return (a, b) => {
        if (~order.indexOf(a.lang) && ~order.indexOf(b.lang)) return order.indexOf(a.lang) - order.indexOf(b.lang)
        if (~order.indexOf(a.lang)) return -1
        if (~order.indexOf(b.lang)) return 1
        return 0
      }
    }
  }

  loadFeatures (playerInstance) {
    // needed to load generic feature defined in player.js
    super.loadFeatures(playerInstance)
    // TODO: translate legacy service into vanilla js
    flowLegacyService.load(playerInstance)

    this.features.merge(new FlowPlayerFeatures(playerInstance))
  }

  handleFeatures () {
    this.features.call('handle')
  }
}
