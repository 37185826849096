/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import * as properties from './ExportProperties'
import { FlowPlayerFeatureBase } from '../../FlowPlayerFeatureBase'
import { MercureHandler } from '../../../../SSE/MercureHandler'
import { PollingHandler } from '../../../../SSE/PollingHandler'
import { ServerEvents } from '../../../../SSE/ServerEvents'
import _toArray from 'lodash/toArray'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import { fcLogger } from '../../../../lib/FcLogger'

export class RealtimeFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor (player) {
    super(player)
  }

  getName() {
    return 'RealtimeFlowPlayerFeature'
  }

  _isPropertyAvailable (property) {
    // Map through the exported properties in order to have a string[] of
    // the available properties (see ExportProperties.js)
    const availableProperties = Object.values(_omit(properties, 'default')).
      map(property => property['getName']())

    return availableProperties.includes(property)
  }

  _handleProperty (property, value) {
    if (!this._isPropertyAvailable(property))
      return fcLogger.warn(`${property} handler is not implemented`, value)

    fcLogger.info(`Handling ${property}...`, value)
    this._getImportedProperty(property).handle(this._player, value)
  }

  _getImportedProperty (searchedProperty) {
    return _toArray(properties).
      find(property => property['getName']() === searchedProperty)
  }

  _subscribeToRealtimeEvents () {
    new MercureHandler({
      callback: this.dispatchServerEvent.bind(this),
      config: {
        topics: _get(this, '_player.config.mercure.topics'),
        hubURL: _get(this, '_player.config.mercure.hub_url'),
        token: _get(this, '_player.config.mercure.token'),
        lastEventId: _get(this, '_player.config.mercure.last_event_id'),
      },
      fallback: () => new PollingHandler({
        callback: this.dispatchServerEvent.bind(this),
        resourceURL: fcEmbedder.getEmbedUrl(this._player.container),
        currentConfig: this._player.currentConfig,
      }),
    })
  }

  handle () {
    if (!_get(this, '_player.config._drt')) {
      this._subscribeToRealtimeEvents()
    }
  }

  dispatchServerEvent (event) {
    // propagate the event within the player instance
    // it is needed if an action has to be taken outside the player
    // (ie. the page hosting the player)
    // TODO: rename the event sent to 'update' and notify BCE devs
    this._player.dispatchEvent(
      new CustomEvent('fcplayerUpdateRealtime', {detail: event.data}))

    const serverEvents = new ServerEvents(this.getParsedEvent(event))

    serverEvents.forEach((serverEvent) => {
      // Special case for player config where each attribute have their own behavior
      if (serverEvent.target.type === 'player_config') {
        Object.keys(serverEvent.target.attributes).
          map((key) => this._handleProperty(key, serverEvent.target.attributes[key]))
      } else {
        this._handleProperty(serverEvent.target.type, serverEvent)
      }
    })
  }

  getParsedEvent(event) {
    return typeof event.data === 'string'
      ? JSON.parse(event.data)
      : event.data
  }
}
