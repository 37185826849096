/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

export class ServerEvent {
  constructor({targetType, targetId, targetAttributes, subjectId, subjectType}) {
    this.target = {
      type: targetType,
      id: targetId,
      attributes: targetAttributes,
    }
    this.subject = {
      id: subjectId,
      type: subjectType,
    }
  }
}
