/**
 * @package   freecaster/luna/player
 * @author    Yannick Delwiche <yannick.delwiche@freecaster.com>,
 *            François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019-20
 */

import _get from 'lodash/get'
import { cast } from './lib/Helper'
import { PlayerFeaturesHandler } from './players/PlayerFeatureHandler'
import { FcProxyFeature } from './players/GenericFeatures/FcProxyFeature'
import { PreviewGenericFeature } from './players/GenericFeatures/PreviewGenericFeature'
import { fcLogger } from './lib/FcLogger'
import {ManageStartEndTimeFeature} from './players/GenericFeatures/ManageStartEndTimeFeature'
import {ClipSectionFeature} from './players/GenericFeatures/ClipSection/ClipSectionFeature'
import {UnmuteBannerFeature} from './players/GenericFeatures/UnmuteBannerFeature'
import {getCurrentScript} from './lib/getCurrentScript'

export default class Player {
  constructor (embedder, element, data) {

    this.embedder = embedder
    this.element = element
    this.player = data.player || {}
    this.video = data.video || {}

    this.features = new PlayerFeaturesHandler()

    this.setupContainer()

    // Force HLSJS version by loading it before flowplayer
    this.loadHLSJS('1.1.5').then(this.loadCssFiles.bind(this))
  }

  loadHLSJS (version = '1.0.2') {
    return new Promise((resolve, reject) => {
      const hlsjsURL = getCurrentScript().src.replace('embedder.js', `externals/hlsjs-${version}.min.js`)

      const scriptEl = document.createElement('script')
      scriptEl.src = hlsjsURL
      scriptEl.onload = scriptEl.onerror = () => resolve()
      document.getElementsByTagName('head')[0].appendChild(scriptEl)
    })
  }

  getElementConfig () {
    let config = {}

    // convert DOMString object into simple Object
    Object.assign(config, _get(this, 'element.dataset') || {})

    // loops through the object and execute the cast fn on  each values
    Object.keys(config).forEach(key => config[key] = cast(config[key]))

    return config
  }

  loadCssFiles () {
    if ((!this.player.css) || (this.player.css.length === 0)) {
      // Second, load JS files
      this.loadJsFiles()
      return
    }

    let file = this.player.css.shift()

    if (this.embedder.isFileLoaded(file)) {
      this.loadCssFiles()
      return
    }

    let element = document.createElement('link')
    element.onload = element.onerror = this.loadCssFiles.bind(this)
    element.rel = 'stylesheet'
    element.type = 'text/css'
    element.media = 'all'
    element.href = file
    document.getElementsByTagName('head')[0].appendChild(element)
  }

  loadJsFiles () {
    if ((!this.player.js) || (this.player.js.length === 0)) {
      // Finally, set up the player
      const playerInstance = this.setupPlayer()
      const fcplayer = addPlayer(playerInstance)

      // this populate the this.features' array
      this.loadFeatures(fcplayer)

      // All the features are attached to the playerInstance
      Object.assign(fcplayer, { fc_features: this.features})
      // We need to wait all features are loaded before calling the handle method
      // on all features
      this.handleFeatures()
      return
    }

    let file = this.player.js.shift()

    if (this.embedder.isFileLoaded(file)) {
      this.loadJsFiles()
      return
    }

    let element = document.createElement('script')
    element.onload = element.onerror = this.loadJsFiles.bind(this)
    element.src = file
    document.getElementsByTagName('head')[0].appendChild(element)
  }

  setupContainer (classes, style) {
    for (let key in this.player.classes) {
      this.element.classList.add(this.player.classes[key])
    }

    for (let key in this.player.style) {
      this.element.style[key] = this.player.style[key]
    }
  }

  setupPlayer () {}
  loadFeatures(playerInstance) {
    this.features.add(new ManageStartEndTimeFeature(playerInstance))
    this.features.add(new PreviewGenericFeature(playerInstance))
    this.features.add(new FcProxyFeature(playerInstance))
    this.features.add(new ClipSectionFeature(playerInstance))
    this.features.add(new UnmuteBannerFeature(playerInstance))
  }

  handleFeatures () {
    fcLogger.info('Handle method not Implemented')
  }
}

export const LockFlags = {
  LOCK_COUNTDOWN: 0x01,
  LOCK_LIVEDONE: 0x02,
  LOCK_PREMIUM: 0x04,
  LOCK_GEOLOCK: 0x08,
}
