/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import {FlowPlayerFeatureBase} from '../FlowPlayerFeatureBase'
import {getUrlParams} from '../../../lib/Helper'
import _get from 'lodash/get'
import {fcLogger} from '../../../lib/FcLogger'
import _debounce from 'lodash/debounce'
import {VIDEO_TYPE} from '../../VideoTypes'

export class AudioLanguageFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor(player) {
    super(player)

    this.audioMenuItemClass = {
      few: 'show-item--few',
      many: 'show-item--many',
    }

    // TODO: should be extracted to a helper function
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setSizePanelLanguageIfMobile()
    }

    if (_get(this, 'videoElement.audioTracks') && (/iPhone|iPod/i.test(navigator.userAgent))) {
      this._player.player.one('loadeddata', this.onIphoneLoadedData.bind(this))
      this.videoElement.audioTracks.addEventListener('change', _debounce(this.onIphoneTrackChange.bind(this), 300))
    } else {
      this._player.player.on('audio:tracks', this.hideLanguages.bind(this))
      this._player.player.on('audio:update', _debounce(this.safelySwitchTrack.bind(this), 300))
    }

    this._player.player.on('volumechange', this.onVolumeChange.bind(this))
    this._player.player.one('loadeddata', this.switchAudioTrack.bind(this))
  }

  onIphoneLoadedData(event) {
    this.safelySwitchTrack({data: this._player.tracks.find(track => track.enabled)})
    this.hideLanguages()
    this.switchAudioTrack()
  }

  onIphoneTrackChange(event) {
    this.safelySwitchTrack(event)
    // flowplayer don't pickup changing track with iPhone in non native mode with hls
    // this forces flowplayer to check if the player is actually playing
    this.videoElement.emit('playing')
  }

  switchAudioLanguage (playerInstance, languageCode) {
    if (_get(playerInstance, 'player.asel')) {
      const tracks =  playerInstance.tracks || []
      const track = tracks.find(track => track.lang === languageCode)

      if (track) {
        playerInstance.player.emit('audio:set', track)
        localStorage.setItem('@flowplayer/audio/track', track.lang)
        return
      }

      return fcLogger.warn(`${languageCode} not found in audio track list`)
    }

    // TODO: implement for DASH
    fcLogger.warn('Could not switch audio track')
  }

  setSizePanelLanguageIfMobile() {
    this._player.container.classList.add('fullscreen-menu')
  }

  getName() {
    return 'AudioLanguageFlowPlayerFeature'
  }

  getAvailableLanguages() {
    return _get(this, '_player.config.available_languages') || []
  }

  updateDisplayedLanguages(availableLanguages, forceReload = false) {

    let currentTrack = this._player.player.asel.get()

    if (!currentTrack.lang) {
      currentTrack = this._player.tracks.find(currentTrack.id)
    }

    // In a case when a video cannot be loaded and thus .asel is not 'on'
    // we still need to be able to handle player displays
    if (!currentTrack) return

    // if the current audio track is removed, we need to switch onto the default one
    if (
      !this.existsInObject(currentTrack.lang, this.getAvailableLanguages())
    ) {
      this.switchAudioLanguage(this._player, this.getDefaultTrack())
    }

    this.hideLanguages()

    if (forceReload && _get(this, '_player.player.hls.url'))
      this._player.player.setSrc(this._player.player.hls.url)
  }

  handle() {}

  switchAudioTrack() {
    // embed-tag attribute > querystring
    const urlParams = getUrlParams(location.href)
    let audioLanguageCode = _get(urlParams, 'audio_language')

    if (_get(this, '_player.config.audioLanguage')) {
      audioLanguageCode = this._player.config.audioLanguage
    }

    if (_get(this, '_player.config.live')) {
      audioLanguageCode = this.mapLanguageCodeWithAvailableLanguage(audioLanguageCode)
    }

    if (audioLanguageCode) {
      this.switchAudioLanguage(this._player, audioLanguageCode)
    }
  }

  /**
   * Try to map input code with keys of available_language
   * @param code {string} - input lang (alpha2 or 3)
   * @return {string} - mapped language code or original code if nothing matches
   */
  mapLanguageCodeWithAvailableLanguage(code) {
    if (
      _get(this, '_player.config.available_languages') &&
      this.existsInObject(code, this._player.config.available_languages)
    ) {
     if (this._player.config.available_languages.map(al => al.alpha2).includes(code))
       return this._player.config.available_languages.find(al => al.alpha2 === code).code

      if (this._player.config.available_languages.map(al => al.alpha3).includes(code))
        return this._player.config.available_languages.find(al => al.alpha3 === code).code
    }

    return code
  }

  /**
   * Check if given string exists in array of properties
   * @param needle {string}
   * @param sourceObject
   * @return {boolean|*}
   */
  existsInObject(needle, sourceObject) {
    if (typeof needle !== 'string') return false
    if (sourceObject.length === 0) return false

    // flatten array of [{ alpha2: 'fr', alpha3: 'fre', code: 'anr' }, ... ]
    // into ['fr', 'fre', 'anr', ...]
    return sourceObject
      .map(c => Object.keys(c)
      .map(k => c[k]))
      .flat()
      .includes(needle)
  }

  hideLanguages() {
    this._player.player.asel.menu.classList.add('hide_audio_track')

    this.addMenuItemClass(this.getAvailableTracks().map(at => at.lang))
  }

  addMenuItemClass(availableLanguages) {
    // reset display before using show-item classes
    this.removeMenuItemClass()

    const liClass =
      availableLanguages.length <= 5
        ? this.audioMenuItemClass.few
        : this.audioMenuItemClass.many
    const tracks = this._player.tracks
    // Html representation of the menu and player.audiotracks matches indexes
    const allowedIndexes = tracks
      .filter(track => availableLanguages.includes(track.lang))
      .map(track => tracks.indexOf(track))

    // filter elements using their index against allowedIndexes
    // add liClass to the rest of the elements
    this.getAudioTrackElements()
      .filter((el, index) => allowedIndexes.includes(index))
      .forEach(el => el.classList.add(liClass))
  }

  removeMenuItemClass() {
    this.getAudioTrackElements().forEach(el => {
      el.classList.remove('show-item--few')
      el.classList.remove('show-item--many')
    })
  }

  getAudioTrackElements() {
    return Array.from(this._player.player.asel.menu.children)
      .filter(el => el instanceof HTMLLIElement)
  }

  /**
   * Catch all audio switch to make sure a user is not trying to listen a track
   * That is not part of available_language
   * @param event
   */
  safelySwitchTrack(event) {
    const getTrackIdFromEvent = (event) => {
      if (event.data && typeof parseInt(event.data.id) === 'number') return parseInt(event.data.id)
      const tracks = Array.from(event.target)
      if (Array.isArray(tracks) && tracks.length > 0) {
        const currentTrack = tracks.find(track => track.enabled)
        if (currentTrack) return parseInt(currentTrack.id)
      }
    }

    const trackId = getTrackIdFromEvent(event);
    const availableTracks = this.getAvailableTracks()

    // check if the track is not part of available languages
    if (!availableTracks.map(a => parseInt(a.id)).includes(trackId)) {
      const defaultTrack = this.getDefaultTrack()
      if (defaultTrack) {
        const player = this._player
        setTimeout(() => this.switchAudioLanguage(player, defaultTrack.lang))
        return fcLogger.warn(`Track with id '${trackId}' is not available, using '${defaultTrack.id} instead'`);
      }

      fcLogger.warn(`Could not switch to a default track'`);
    }
  }

  getAvailableTracks() {
    return this.getAvailableLanguages().length > 0 && _get(this, '_player.config.video_type') === VIDEO_TYPE.LIVE
      ? this._player.tracks.filter(track => this.getAvailableLanguages().map(al => al.code).includes(track.lang))
      : this._player.tracks
  }

  getDefaultTrack() {
    const availableTracks = this.getAvailableTracks()
    const originalTrack = availableTracks.find(a => a.name === 'original')
    const defaultTrack = availableTracks.find(a => a.default === true)

    // Original > default > first index
      return originalTrack
        ? originalTrack
        : defaultTrack
          ? defaultTrack
          : availableTracks[0]
  }

  onVolumeChange () {
    if (this._player.muted) return

    const audioSelectionBtn = this._player.container.querySelector('.fp-asel')

    if (audioSelectionBtn) audioSelectionBtn.click()
  }
}
