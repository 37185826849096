/**
 * @package   freecaster/luna/player
 * @author    Yannick Delwiche <yannick.delwiche@freecaster.com>
 * @copyright Freecaster 2019
 */
import MessageSender from './MessageSender'

export default class ProxyServer extends MessageSender {
  constructor (player) {
    let channel = new MessageChannel()
    super(channel.port1)

    this.player = player

    console.debug('Notifying parent')
    window.parent.postMessage('fcAddPlayer', '*', [channel.port2])
  }

  receiveMessage (event) {
    let message = super.receiveMessage(event)

    let action = message.message.split(':')[0]
    let property = message.message.split(':')[1]

    switch (action) {
      case 'get':
        this.replyToMessage(message.id, this.player[property])
        break;
      case 'set':
        this.player[property] = message.data
        this.replyToMessage(message.id, this.player[property])
        break;
      case 'call':
        let result = this.player[property].apply(this.player, message.data)
        if (result instanceof Promise) {
          result.then((value) => {
            this.replyToMessage(message.id, value)
          }).catch(() => {
            this.replyToMessage(message.id, null)
          })
          break;
        }
        this.replyToMessage(message.id, result)
        break;
    }
  }
}
