/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import $ from 'jquery'

// TODO: transfer legacy features into its own file then remove
class FlowLegacyService {
  load (player) {
    setupAccessibility(player)
    setupVerticalVolume(player)
    setupAudioSelection(player)
    updateTranslations(player)
  }
}

function setupVerticalVolume (player) {
  var container = $(player.container)
  var controlbar = container.find('.fp-ui:not(.fp-ad-ui)')
  var volume_slider = controlbar.find('.fp-volume')
  var volume_button = controlbar.find('.fp-volumebtn')
  var volume_main_button = volume_slider

  if (container.hasClass('fp-volumebar-horizontal')) {
    return
  }

  /// IE Does not support CSS variables. Disable vertical volume
  var isIE11 = !!window.MSInputMethodContext && !!document.documentMode
  if (isIE11) {
    return
  }

  // Setup vertical volume
  var volume_dragging = false
  setVolume()
  player.player.on('volumechange', setVolume)
  container.addClass('fc-vertical-volume')

  // Clone volume to remove default behavior
  var new_button = volume_button.clone()
  volume_button.replaceWith(new_button)
  volume_main_button = volume_button = new_button
  volume_button.on('click', function (e) {
    if (e.target !== this)
      return true

    if (!player.muted && player.volume <= .05) {
      player.volume = .1
      return
    }
    player.muted = !player.muted
  })
  volume_button.attr('role', 'slider').
    attr('tabindex', 0).
    attr('aria-valuemin', 0).
    attr('aria-valuemax', 1).
    attr('aria-valuenow', player.volume)

  var new_vol = volume_slider.clone()
  volume_slider.replaceWith(new_vol)
  volume_slider = new_vol
  // Add knob
  var knob = $('<div>').addClass('fc-volume-knob')
  volume_slider.append(knob)
  volume_slider.appendTo(volume_button)
  volume_slider.removeAttr(
    'tabindex role aria-valuemin aria-valuemax aria-valuenow')

  container.find('.fp-ui .fp-volume').on('mousemove', function (e) {
    // Handle volume slide
    if (volume_dragging) {
      updateVolume(this, e)
    }
  }).on('mousedown', function (e) {
    // Handle click
    updateVolume(this, e)
    volume_dragging = true
  }).on('mouseup', function () {
    volume_dragging = false
  })
  // Timeout for showing the volume slider
  var hide_timeout = null
  volume_button.addClass('fc-hide-volume-slider')
  volume_button.on('mouseleave', function () {
    volume_dragging = false

    hide_timeout = setTimeout(function () {
      volume_button.addClass('fc-hide-volume-slider')
      volume_button.blur()
    }, 500)

  }).on('mouseenter', function () {
    // Timeout to opening the volume control
    if (hide_timeout)
      clearTimeout(hide_timeout)

    volume_button.removeClass('fc-hide-volume-slider')
  })
  // Update volume aria attr
  player.player.on('volumechange', function (e) {
    // volume_button.attr('aria-expanded', !player.muted);
    volume_button.attr('aria-valuenow', player.volume)
  })

  function updateVolume (el, ev) {
    var a = $(el).offset().top + parseInt($(el).css('border-left-width'))
    var borders = parseInt($(el).css('border-right-width'))
    var s = el.clientWidth - borders
    var t = ev.pageY - a
    if (t > s) {
      t = s
    } else if (t < 0) {
      t = 0
    }

    var vol = t / s
    player.volume = 1 - vol
    player.muted = vol === 0
    setVolume()
  }

  function setVolume () {
    var vol = player.volume
    if (player.muted) {
      vol = 0
    }
    document.body.style.setProperty('--volume', (vol * 100) + '%')
    if (volume_slider.get(0)) {
      document.body.style.setProperty('--volume-knob',
        (vol * volume_slider.get(0).clientWidth) + 'px')
    }
  }
}

function setupAccessibility (player) {
  var container = $(player.container)
  // Navigation outline
  // Hack to keep the controlbar visible when an element is focused, but only if we navigated via keyboard
  var manualKeyBoardNavigation = false
  // Focus outline is needed for accessibility when navigating with keyboard. Toggle outline on manual navigation
  var focusRingStyle = window.getComputedStyle(document.body).
    getPropertyValue('--outline')

  container.on('keyup', function (e) {
    if (e.keyCode === 9) // Tab
    {
      manualKeyBoardNavigation = true
      document.body.style.setProperty('--outline', focusRingStyle)
    }
  })

  // Mouse input hides controlbar
  container.on('pointerdown', function (e) {
    document.body.style.setProperty('--outline', 'none')
    manualKeyBoardNavigation = false
  })

  // Hide qsel HD text
  var qsel = container.find('.fp-qsel')
  if (qsel) qsel = qsel.get(0)
  if (qsel) qsel = qsel.childNodes
  if (qsel) qsel = qsel[0]
  if (qsel) qsel.nodeValue = ''
}

function updateTranslations (player) {
  var controlbar = $(player.container).find('.fp-ui:not(.fp-ad-ui)')
  var container = $(player.container)
  var volume_slider = controlbar.find('.fp-volume')
  var volume_button = controlbar.find('.fp-volumebtn')
  var volume_main_button = volume_slider
  var embed_code_label = 'Embed code copied to clipboard'

  if (!player.player.translator) {
    return
  }
  // CC Button
  var cc_label = player.player.translator.translate('core.captions')
  var cc_button = controlbar.find('.fp-cc').attr('aria-label', cc_label)

  // Timeline
  var timeline_label = player.player.translator.translate('core.timeline')
  var timeline_slider = controlbar.find('.fp-timeline').
    attr('aria-label', timeline_label)

  // Volume
  var volume_label = player.player.translator.translate('core.volume')
  if (volume_main_button) volume_main_button.attr('aria-label', volume_label)

  embed_code_label = player.player.translator.translate('share.clipboard_success')
}

function setupAudioSelection (player) {
  player.player.on('audio:tracks', function (e) {
    var found = []
    var count = 0
    var tracks = player.tracks
    var icon = $(player.player.asel.menu.parentElement)

    if (tracks && Array.isArray(tracks)) {
      if (tracks.length > 1) {
        // Ignore duplicate audio tracks
        for (var i = 0; i < tracks.length; i++) {
          var name = tracks[i].name
          if (found[name] === undefined) {
            found[name] = tracks[i]
            count++
          }
        }

        if (count > 1) {
          icon.show()
          return
        }
      }
    }

    icon.hide()
  })
}

export const flowLegacyService = new FlowLegacyService()
