/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2021
 */

import {PlayerFeatureBase} from '../PlayerFeatureBase'
import {BaseVideoEvents} from '../baseVideoEvents'
import {createHtmlElementFromString} from '../../lib/Helper'

export class UnmuteBannerFeature extends PlayerFeatureBase {
  constructor (player) {
    super(player)

    this.videoElement = this._player.container.querySelector('video')
    this.elementClass = 'fc-unmute-banner'
    this.displayUnmuteBanner = this._player.muted

    this.add()
    setTimeout(this.toggleUnmuteBanner.bind(this))

    this.videoElement.addEventListener(BaseVideoEvents.VOLUMECHANGE,
      this.onVolumeChange.bind(this))
  }

  createElement () {
    const element = createHtmlElementFromString(`
        <div class="${this.elementClass}"></div>
    `)
    const span = createHtmlElementFromString(`
        <span>Click to unmute</span>
    `)

    span.addEventListener('click', this.onBannerClick.bind(this))

    element.appendChild(span)

    return element
  }

  add () {
    if (this.getElement()) return

    const element = this.createElement()

    this._player.container.insertAdjacentElement('afterbegin',
      element)
  }

  remove () {
    const element = this.getElement()

    if (element) element.remove()
  }

  getElement () {
    return this._player.container.querySelector(`.${this.elementClass}`)
  }

  getName () {
    return 'UnmuteBannerFeature'
  }

  onVolumeChange () {
    this.displayUnmuteBanner = this._player.muted || this._player.volume === 0
    this.toggleUnmuteBanner()
  }

  onBannerClick (event) {
    event.stopPropagation()

    const volumeBtn = this._player.container.querySelector('.fp-volumebtn')

    volumeBtn.click();
  }

  toggleUnmuteBanner () {
    const element = this.getElement()

    if (!element) return

    if (this.displayUnmuteBanner) element.classList.add('active')
    else element.classList.remove('active')
  }
}
