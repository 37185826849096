/**
 * @package   freecaster/luna/player
 * @author    Yannick Delwiche <yannick.delwiche@freecaster.com>
 * @copyright Freecaster 2019
 */

import _get from 'lodash/get'
import anyplayer from 'anyplayer/src/anyplayer.js'
import ProxyClient from './proxy/ProxyClient'
import ProxyServer from './proxy/ProxyServer'
import { playerFactory } from './players/PlayerFactory'

export default class CallbackService {
  constructor (isIframe) {
    this.playerInstances = []

    this.isIframe = !!isIframe

    this.declareCallbacksArray()

    window.addEventListener('message', this.onMessage.bind(this))

    window.addPlayer = this.addPlayer.bind(this)
    window.fcplayer  = this.getPlayer.bind(this)
    window.fcplayers  = this.getPlayers.bind(this)
    window.createPlayer = playerFactory.create
    window.fcRemoveAllPlayersFromInstance = this.removeAllPlayersFromInstance.bind(this)
    window.fcRemovePlayerFromInstance = this.removePlayerFromInstance.bind(this)
  }

  addPlayer (player) {
    if (player instanceof ProxyClient) {
      player = player.getVideoElement()
    } else {
      // noinspection JSUnresolvedFunction
      player = anyplayer(player)
    }

    this.playerInstances.push(player)

    for (let i = 0; i < _fcpr.length; i++) {
      try {
        _fcpr[i].call(this, player)
      } catch (exception) {
        console.warn('Player callback exception', exception)
      }
    }

    if ((this.isIframe) && (window.parent !== window)) {
      new ProxyServer(player)
    }

    this.observePlayer(player)

    return player
  }

  /**
   * Observe the DOM and observe the changes of the player
   * Removes it if it does not exists in the DOM
   * @param player
   */
  observePlayer (player) {
    const observer = new MutationObserver((mutations, observer) => {

      if (!document.body.contains(player.container)) {
        this.removePlayerFromInstance(player)
        observer.disconnect()
      }
    })

    observer.observe(document.body, {childList: true})
  }

  declareCallbacksArray () {
    let _this = this

    window._fcpr = window._fcpr || []

    Object.defineProperty(window._fcpr, 'push', {
      configurable: false,
      enumerable: false,
      writable: false,
      value: function (...args) {
        let n = this.length
        args.forEach((callback) => {
          this[n++] = callback
          _this.playerInstances.forEach((playerInstance) => {
            try {
              callback(playerInstance)
            } catch (e) { }
          })
        })
        return n
      },
    })
  }

  /**
   * Return the player associated with an ID.
   * If no id is provided, returns the first created player.
   * @param id
   * @returns {T | *}
   */
  getPlayer (id) {
    if (id) {
      const player = this.playerInstances.find(
        instance => instance.id === id)

      if (player) return player

      console.warn('No player was found with id', id)
      return null
    }

    if (this.playerInstances.length > 0)
      return this.playerInstances[0]

    console.warn('No player was found')
    return null
  }

  getPlayers() {
    return this.playerInstances
  }

  onMessage (event) {
    if (event.data === 'fcAddPlayer') {
      let player = new ProxyClient(event)
      addPlayer(player)
    }
  }

  /**
   * Remove an instance from the array, if no playerinstance is provided,
   * removes the one returned by getPlayer
   * @param playerInstance
   */
  removePlayerFromInstance (playerInstance) {
    let instanceToRemove = playerInstance
      ? playerInstance
      : this.getPlayer()

    if(!instanceToRemove) {
      console.warn('No instance to remove...')
      return false
    }

    console.log('Instance no longer valid, removing it', instanceToRemove)

    // cleanup instances of the player library
    if (typeof _get(playerInstance, 'destroy') === 'function') playerInstance.destroy()

    this.playerInstances = this.playerInstances.filter(
      instance => instance !== instanceToRemove)
  }

  /**
   * Removes all the playerInstances
   */
  removeAllPlayersFromInstance () {
    this.playerInstances.map(instance => this.removePlayerFromInstance(instance))
  }
}
