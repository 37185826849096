/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import _get from 'lodash/get'
import {FlowPlayerFeatureBase} from '../FlowPlayerFeatureBase'

export class VolumeFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor (player) {
    super(player)

    if (typeof _get(this, '_player.config.volume') === 'number') {
      this.setVolume(this._player.config.volume)
    }
  }

  getName () {
    return 'VolumeFlowPlayerFeature'
  }

  setVolume (volume) {
    const handleVolume = (volume) => {
      if (volume > 1)
        return 1

      if (volume < 0)
        return 0

      return volume
    }

    volume = handleVolume(volume)

    if (this._player.muted || this._player.config.muted)
      volume = 0

    this._player.volume = volume
  }
}
