/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import { FlowPlayerFeatureBase } from '../FlowPlayerFeatureBase'

export class ExtraButtonsFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor (player) {
    super(player)

    // TODO: remove and handle everything in css
    this.addClassToPlayButtons()
    if (this.cc) this.cc.classList.add('fp-css')
  }

  getName() {
    return 'ExtraButtonsFlowPlayerFeature'
  }

  addClassToPlayButtons () {
    const playClass = 'fc-play-button'
    this._player.container.querySelector('.fp-switch.fp-play-1').classList.add(playClass)
    this._player.container.querySelector('.fp-switch.fp-play-2').classList.add(playClass)
    this._player.container.querySelector('.fp-switch.fp-play-3').classList.add(playClass)
    if (this._player.container.querySelector('.fp-small-switch.fp-btns'))
      this._player.container.querySelector('.fp-small-switch.fp-btns').classList.add(playClass)
  }
}
