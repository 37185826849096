/**
 * @package   freecaster/luna/player
 * @author Daniel Vos
 * @copyright Freecaster 2019
 */

import { FlowPlayerFeatureBase } from '../FlowPlayerFeatureBase'
import { createHtmlElementFromString } from '../../../lib/Helper'

export class PopoutFlowPlayerFeature extends FlowPlayerFeatureBase {

  constructor (player) {
    super(player)

    this.setupPopOut()
  }

  getName() {
    return 'PopoutFlowPlayerFeature'
  }

  setupPopOut () {
    // check if the icon already exist
    if (this.getElement()) return

    // prevent newly opened window to generate the icon
    if (window.opener) return

    // create popout icon
    this.icon = this.createPopOutIcon()

    // insert it into player
    this._player.insertIcon(this.icon, {before: 'fullscreen'})

    // register icon events
    this.registerIconEvents()
  }

  removeButton () {
    const popout = this.getElement()
    if (popout) popout.remove()
  }

  getElement () {
    return this._player.container.querySelector('.fp-popout')
  }

  createPopOutIcon () {
    return createHtmlElementFromString(`
    <div class="fp-popout"
         role="button" 
         tabindex="0"  
         aria-label="popout" 
         aria-expanded="false" 
         aria-haspopup="true"></div>
    `)
  }

  registerIconEvents () {
    this.icon.addEventListener('click', this.openNewWindow.bind(this))
  }

  async openNewWindow (e) {
    const url = this.renderWindowUrl(this._player)
    const newWin = window.open(url, 'freecaster_player',
      'height=360,width=640,dependent,resizable')

    await this.windowReadyPromise(newWin)

    this.registerNewWinListeners(newWin)
    this._player.pause(true)
  }

  windowReadyPromise(winObj) {
    return new Promise((resolve, reject) => {
      // IE11 fix: we need to wait the window object to have the "addEventListener"
      // function ready in order to have it register event listener to a "window.open" object
      const interval = setInterval(() => {
        if (typeof winObj.addEventListener === 'function') {
          clearInterval(interval)
          resolve(true)
        }
      }, 100)
    })
  }

  registerNewWinListeners(obj) {
    obj.window.onbeforeunload = (event) => {
      const newWinplayer = event.currentTarget.fcplayer()
      this.setTime({
        time: newWinplayer.currentTime,
        player: newWinplayer,
      })
    }

    //add overflow: hidden in popout
    //fix flashing
    obj.window.onloadeddata = (event) => {
      let bodySelector = newWin.document.getElementsByTagName('body')[0]
      bodySelector.style.overflow = 'hidden'
    }
  }

  renderWindowUrl ({config, currentTime, paused}) {
    const params = [
      `start_time=${currentTime}`,
      `autoplay=${!paused}`,
      '_dw=true'
    ]
    // generate url for new window pop-out
    let url = fcEmbedder.getEmbedUrl(this._player.container)

    if (url.indexOf('.json') > -1)
      url = url.replace('.json', '.html')

    const queryOperator = url.indexOf('?') > -1 ? '&' : '?'
    return `${url}${queryOperator}${params.join('&')}`
  }

  setTime ({time, player}) {
    this._player.currentTime = time
    if (!player.paused) this._player.player.togglePlay(true)
  }
}
