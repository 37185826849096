import {ClipSection} from './ClipSection.class'
import {fcLogger} from '../../../lib/FcLogger'
import moment from 'moment-timezone'

export class ClipSections {
  /**
   *
   * @param rawClipSections {array}
   * @param videoStart {number} - absolute time of the video's start
   */
  constructor (rawClipSections, videoStart) {
    this.sections = rawClipSections.map(section => new ClipSection({ start: section[0], end: section[1], videoStart: videoStart}))
  }

  getTimestamp(currentTime) {
    const sectionInfo = this.getSectionInfo(currentTime)

    if (!sectionInfo) {
      fcLogger.warn(
        'Could not find a suitable section to compute absolute time'
      )
      return null
    }

    return parseInt(
      moment(
        sectionInfo.elapsedMs + sectionInfo.absoluteStart
      ).format('x')
    )
  }

  getSectionInfoByTimestamp(timestamp) {
    if (typeof timestamp !== 'number') {
      fcLogger.warn('You must provide correct currentTime')
      return null
    }

    if (Number.parseInt(timestamp).toString().length !== 13) {
      fcLogger.warn('You must provide correct timestamp in milliseconds')
      return null
    }

    const section = this.sections.find(section => timestamp <= section.absoluteEnd && timestamp >= section.absoluteStart)

    if (!section) {
      fcLogger.warn('Input timestamp not found')
      return null
    }

    let previousSectionsDuration = 0
    const previousSections = this.sections.filter((s, index) => this.sections.indexOf(section) > index)

    if (previousSections.length > 0)
      previousSectionsDuration = previousSections.map(section => section.duration).reduce((sum, duration) => sum  + duration)

    return {
      ...section,
      elapsedMs: timestamp - section.absoluteStart, // ms elapsed within the section
      currentTimeMs: previousSectionsDuration + (timestamp - section.absoluteStart) // ms elapsed from the beginning of the video
    }
  }

  getSectionInfo(currentTime) {
    if (typeof currentTime !== 'number') {
      fcLogger.warn('You must provide correct currentTime')
      return null
    }

    currentTime = currentTime * 1000

    let sectionInfo = null

    this.sections
      .map(section => section.duration)
      .reduce((accumulator, duration, i) => {
        const totalDuration = accumulator + duration

        if (currentTime >= accumulator && currentTime <= totalDuration) {
          sectionInfo = {
            ...this.sections[i],
            elapsedMs: currentTime - accumulator, // ms elapsed within the section
          }
        }

        return totalDuration
      }, 0)

    return sectionInfo
  }
}
