/**
 * @package   freecaster/luna/player
 * @author    Yannick Delwiche <yannick.delwiche@freecaster.com>
 * @copyright Freecaster 2019
 */

export default class MessageSender {
  constructor (port) {
    this.port = port
    this.port.onmessage = this.receiveMessage.bind(this)

    this.messages = {}
  }

  receiveMessage (event) {
    let message = event.data

    console.debug('Received message', message)

    if (this.messages.hasOwnProperty(message.id)) {
      this.messages[message.id] = message.message
    }

    return message;
  }

  replyToMessage (id, message) {
    this.port.postMessage({ id, message })
  }

  sendMessage (message, data) {
    let id = Math.floor((Date.now() + Math.random()) * 1000)

    this.port.postMessage({ id, message, data })

    return id
  }

  sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async waitForReply (messageId) {
    this.messages[messageId] = null

    while (this.messages[messageId] === null) {
      await this.sleep(10)
    }

    let answer = this.messages[messageId]
    delete this.messages[messageId]

    return answer
  }
}
