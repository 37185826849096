/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import _get from 'lodash/get'
import {PlayerFeatureBase} from '../PlayerFeatureBase'

export class FcProxyFeature extends PlayerFeatureBase {
  constructor (player) {
    super(player)

    if (_get(this, '_player.currentConfig.fcproxy'))
      this.appendScriptToHead(this._player.currentConfig.fcproxy)
  }

  getName() {
    return 'FcProxyFeature'
  }

  appendScriptToHead(url) {
    if (!url) return

    const element = document.createElement('script')
    element.setAttribute('type', 'text/javascript')
    element.setAttribute('src', url)
    element.async = true

    document.getElementsByTagName('head')[0].appendChild(element)
  }
}
