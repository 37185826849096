/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import {FlowPlayerFeatureBase} from '../FlowPlayerFeatureBase'

export class AutoplayFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor(props) {
    super(props)
  }

  getName() {
    return 'AutoplayFlowPlayerFeature'
  }

  handle() {}

  setAutoplay(value) {
    this._player.autoplay = value
    this._player.player.setOpts({autoplay: value})
  }
}
