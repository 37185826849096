/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import { PlayerProperty } from '../PlayerProperty'

class ChapterProperty extends PlayerProperty {
  /**
   * @param {Object} player - The player instance object
   * @param {ServerEvent} value
   */
  handle (player, value) {
    const ChaptersFlowPlayerFeature = player.fc_features.get('ChaptersFlowPlayerFeature')

    if (!value.target.attributes)
      return ChaptersFlowPlayerFeature.remove({id: value.target.id})

    ChaptersFlowPlayerFeature.addOrUpdate(
      {...value.target.attributes, id: value.target.id})
  }

  getName () {
    return 'chapter'
  }
}

export const autoplayProperty = new ChapterProperty()
