import _get from 'lodash/get'
import moment from 'moment-timezone'
import {fcLogger} from '../lib/FcLogger'

class MuxHelper {
  constructor () {
    this.playerInstances = []
  }

  init(instance, container) {
    if (typeof initFlowplayerMux === 'function') {
      initFlowplayerMux(instance, container, {
        debug: false,
        data: {
          env_key: _get(instance, 'config.trackers.mux.env_key') || '',
          player_name: _get(instance, 'config.trackers.mux.player_name') || 'Freecaster - Flowplayer',
          player_version: flowplayer.version,
          player_init_time: _get(instance, 'config.trackers.mux.player_init_time')
            ? parseInt(moment(instance.config.trackers.mux.player_init_time).format('x'))
            : Date.now(),
          video_stream_type: instance.config.live
            ? 'live'
            : 'on-demand',
          video_duration: instance.duration,
          video_id: instance.config.video_id,
          video_title: instance.config.title,
        },
      })

      this.playerInstances.push(instance)

    } else {
      fcLogger.warn('Could not init MUX')
    }
  }

  getInstance(instance) {
    return this.playerInstances.find(instance)
  }

  /**
   *
   * @param playerInstance
   * @param data - ie. { eventName: 'name', eventData: { video_id: '12456' }}
   * see https://docs.mux.com/docs/metadata for available eventData properties
   * @return {*|null}
   */
  emit(playerInstance, data) {
    const p = this.getInstance(playerInstance)

    if (typeof _get(p, 'mux.emit') === 'function') {
      return p.mux.emit(data.eventName, data.eventData)
    }

    fcLogger.warn('Could emit Mux event')
  }
}

export const muxHelper = new MuxHelper()
