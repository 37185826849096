/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 * https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
 */

export const BaseVideoEvents = {
  ABORT : 'abort',
  CANPLAY : 'canplay',
  CANPLAYTHROUGH: 'canplaythrough',
  DURATIONCHANGE: 'durationchange',
  EMPTIED: 'emptied',
  ENCRYPTED: 'encrypted',
  ENDED: 'ended',
  ERROR: 'error',
  INTERRUPTBEGIN: 'interruptbegin',
  INTERRUPTEND: 'interruptend',
  LOADEDDATA: 'loadeddata',
  LOADEDMETADATA: 'loadedmetadata',
  LOADSTART: 'loadstart',
  MOZAUDIOAVAILABLE: 'mozaudioavailable',
  PAUSE: 'pause',
  PLAY: 'play',
  PLAYING: 'playing',
  PROGRESS: 'progress',
  RATECHANGE: 'ratechange',
  SEEKED: 'seeked',
  SEEKING: 'seeking',
  STALLED: 'stalled',
  SUSPEND: 'suspend',
  TIMEUPDATE: 'timeupdate',
  VOLUMECHANGE: 'volumechange',
  WAITING: 'waiting',
}
