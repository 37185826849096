/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import _get from 'lodash/get'
import {ServerEventEnum} from './ServerEventEnum'
import {fcLogger} from '../lib/FcLogger'
import {ServerEvent} from './ServerEvent'

const eventList = {
  [ServerEventEnum.CONTENT_CHILD_ADDED]: 'handleChildContent',
  [ServerEventEnum.CONTENT_CHILD_REMOVED]: 'handleChildContent',
  [ServerEventEnum.CONTENT_CREATED]: 'handleContents',
  [ServerEventEnum.CONTENT_DELETED]: 'handleContents',
  [ServerEventEnum.CONTENT_UPDATED]: 'handleContents',
  [ServerEventEnum.PLAYER_CONFIG_UPDATED]: 'handlePlayerConfig',
  [ServerEventEnum.PLAYER_DISPLAY_UPDATED]: 'handlePlayerDisplay',
  [ServerEventEnum.POSTER_EXTRACTED]: 'notImplemented',
  [ServerEventEnum.STORAGE_FILE]: 'notImplemented',
  [ServerEventEnum.STORAGE_FILE_UPLOADED]: 'notImplemented',
  [ServerEventEnum.THUMBNAILS_CREATED]: 'handleThumbnails',
}

/**
 * Return an uniform ServerEvents from Luna's API's payloads
 */
export class ServerEvents {
  /**
   *
   * @param rawEvent
   * @return {[ServerEvent]}
   */
  constructor(rawEvent) {
    this.events = []
    const eventName = rawEvent.event

    if (!Object.keys(eventList).includes(eventName)) {
        fcLogger.warn(`${eventName} is not a recognized event`)
      return this.events
    }

    // Use the function assiociated with the event name
    // ie. if 'content.child_added' is received, it will launch handleChildContent method
    this[eventList[eventName]](rawEvent.data)

    return this.events
  }

  addEvent({
    targetType,
    targetId,
    targetAttributes,
    subjectId,
    subjectType,
  }) {
    this.events.push(
      new ServerEvent({
        targetType,
        targetId,
        subjectId,
        subjectType,
        targetAttributes,
      })
    )
  }

  handleContent (event) {
    this.addEvent({
      targetType: event.content.type,
      targetId: event.content.id,
      targetAttributes: event.changes,
      subjectId: null,
      subjectType: null,
    })
  }

  handlePlayerConfig (event) {
    this.addEvent({
      targetType: 'player_config',
      targetId: null, // there is no specific ID for a single playerconfig property
      targetAttributes: event.changes,
      subjectId: event.content.id,
      subjectType: event.content.type,
    })
  }

  handlePlayerDisplay (event) {
    this.addEvent({
      targetType: 'player_display',
      targetId: null, // is there an specific ID for a player display ?
      targetAttributes: event.changes.player_display,
      subjectId: event.content.id,
      subjectType: event.content.type,
    })
  }

  handleAvailableLanguages (event) {
    this.addEvent({
      targetType: 'available_languages',
      targetId: null,
      targetAttributes: event.changes,
      subjectId: event.content.id,
      subjectType: event.content.type,
    })
  }

  handleShouldReload(event) {
    this.addEvent({
      targetType: 'should_reload',
      targetId: null,
      targetAttributes: event.changes,
      subjectId: event.content.id,
      subjectType: event.content.type,
    })
  }

  handleStart(event) {
    this.addEvent({
      targetType: 'start',
      targetId: null,
      targetAttributes: event.changes.start,
      subjectId: event.content.id,
      subjectType: event.content.type,
    })
  }

  handleChildContent(event) {
    this.addEvent({
      targetType: event.child.type,
      targetId: event.child.id,
      targetAttributes: event.child.attributes,
      subjectId: event.content.id,
      subjectType: event.content.type,
    })
  }

  handleThumbnails(event) {
    this.addEvent({
      targetType: 'vtt_url',
      targetId: null,
      targetAttributes: event.vtt_url,
      subjectId: event.content.id,
      subjectType: event.content.type
    })
  }

  handleContents (event) {
    if (_get(event, 'changes')) {
      if (event.changes.hasOwnProperty('should_reload'))
        this.handleShouldReload(event)
      if (event.changes.hasOwnProperty('player_display'))
        this.handlePlayerDisplay(event)
      if (Object.keys(event.changes).length <= 3 && event.changes.hasOwnProperty('available_languages') && event.changes.hasOwnProperty('stream_channels'))
        this.handleAvailableLanguages(event)
      if (event.changes.hasOwnProperty('start'))
        this.handleStart(event)

      return
    }

    return this.handleContent(event)
  }

  notImplemented (event) {
    fcLogger.warn(
      `Handler is not implemented for this type of event (${event.event}`,
    )
  }
}
