/**
 * AvailableLanguagesProperty.js client-side code
 *
 * @package   Freecaster freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright (c) 2020 Freecaster SPRL
 */
import { PlayerProperty } from '../PlayerProperty'

class AvailableLanguages extends PlayerProperty {
  /**
   *
   * @param player
   * @param {ServerEvent} value
   */
  handle (player, value) {
    const audioLanguageFeature = player.fc_features.get('AudioLanguageFlowPlayerFeature')

    const available_languages = value.target.attributes.available_languages || []

    player.config.available_languages = available_languages

    const has_changed_channels = Array.isArray(value.target.attributes.stream_channels)

    audioLanguageFeature.updateDisplayedLanguages(available_languages, has_changed_channels)
  }

  getName () {
    return 'available_languages'
  }
}

export const availableLanguages = new AvailableLanguages()
