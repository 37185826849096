/**
 * @package   freecaster/luna/player
 * @author    Yannick Delwiche <yannick.delwiche@freecaster.com>
 * @copyright Freecaster 2019
 */

import { polyfills} from './lib/polyfills'
import 'custom-event-polyfill/polyfill'
import Embedder from './Embedder'
import { getCurrentScript } from './lib/getCurrentScript'

polyfills()

new Embedder(getCurrentScript().dataset)
