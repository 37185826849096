/**
 * @package   freecaster/luna/player
 * @author    Yannick Delwiche <yannick.delwiche@freecaster.com>
 * @copyright Freecaster 2019
 */

export const get = (url, onSuccess = null, onError = null) => {
  let xhr = new XMLHttpRequest()
  xhr.open('GET', url)
  xhr.onload = function () {
    if (xhr.status === 200) {
      let data = xhr.responseText
      if (xhr.getResponseHeader('content-type') === 'application/json') {
        data = JSON.parse(data)
      }
      if (onSuccess) {
        onSuccess(data)
      }
    } else {
      if (onError) {
        onError()
      }
    }
  }
  xhr.send()
}
