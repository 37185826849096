/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

/**
 * These events are found in luna/app/Events
 */
export const ServerEventEnum = {
  CONTENT_CHILD_ADDED: 'content.child_added',
  CONTENT_CHILD_REMOVED: 'content.child_removed',
  CONTENT_CREATED: 'content.created',
  CONTENT_DELETED: 'content.deleted',
  CONTENT_UPDATED: 'content.updated',
  PLAYER_CONFIG_UPDATED: 'player_config.updated',
  PLAYER_DISPLAY_UPDATED: 'player_display.updated',
  POSTER_EXTRACTED: 'poster.extracted',
  STORAGE_FILE: 'storage.file',
  STORAGE_FILE_UPLOADED: 'storage.file_uploaded',
  THUMBNAILS_CREATED: 'thumbnails_vtt.created',
}
