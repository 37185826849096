/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import _get from 'lodash/get'
import {FlowPlayerFeatureBase} from '../FlowPlayerFeatureBase'

export class LiveIndicatorFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor (player) {
    super(player)

    this.handleIndicatorInterval = null

    // TODO: make this configurable
    this.liveIndicator = {
      edge: {
        text: 'Live',
        class: 'fc-is-live'
      },
      dvr: {
        text: 'Resume live',
        class: 'fc-is-dvr'
      }
    }

    this.registerHlsEvents()
  }

  reload() {
    this.registerHlsEvents()
  }

  registerHlsEvents() {
    if (_get(this, '_player.player.hls') && this.handleIndicatorInterval === null) {
      this.handleIndicatorInterval = setInterval(this.handleIndicator.bind(this), 1000)
    }
  }

  getName () {
    return 'LiveIndicatorFlowPlayerFeature'
  }

  handleIndicator() {
    if (!this._player.config.live) return

    this.setContainerClass()
    this.setIndicatorTextStatus()
  }

  setContainerClass() {
    if (!_get(this, '_player.container.classList')) return

    if (this.isLiveSync()) {
      this._player.container.classList.remove(this.liveIndicator.dvr.class)
      this._player.container.classList.add(this.liveIndicator.edge.class)
    } else {
      this._player.container.classList.remove(this.liveIndicator.edge.class)
      this._player.container.classList.add(this.liveIndicator.dvr.class)
    }
  }

  setIndicatorTextStatus () {
    const liveIndicatorEl = this.getLiveIndicatorEl()

    if (liveIndicatorEl) {
      const liveText = liveIndicatorEl.querySelector('.fp-live-text')

      liveText.innerHTML = !this.isLiveSync()
        ? this.liveIndicator.dvr.text
        : this.liveIndicator.edge.text
    }
  }

  getLiveIndicatorEl () {
    return this._player.container.querySelector('.fp-live-status')
  }

  isLiveSync() {
    // if we cannot compute, we return true just to indicate we are LIVE but unable to say if we are playing the archive or the edge.
    if (!this._player.edgeTimestamp || !this._player.currentTimestamp) return true

    return Math.floor((this._player.edgeTimestamp - this._player.currentTimestamp) / 1000) <= 60
  }
}
