export class ClipSection {
  /**
   *
   * @param start {number} - relative time in miliseconds
   * @param end {number} - relative time in miliseconds
   * @param videoStart {number} - absolute time of start of the video in miliseconds
   */
  constructor ({ start, end, videoStart}) {
    this.start = start
    this.end = end
    this.duration = end - start
    this.absoluteStart = videoStart + start
    this.absoluteEnd = videoStart + end
  }

}
