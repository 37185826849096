/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import { FlowPlayerFeatureBase } from '../FlowPlayerFeatureBase'
import { createHtmlElementFromString } from '../../../lib/Helper'

export class FullscreenFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor(player) {
    super(player)

    const icon_fullscreen = this._player.container.querySelector(
      '.fp-fullscreen')
    const icon_fullscreen_exit = this._player.container.querySelector(
      '.fp-fullscreen-exit')

    // create fullscreen button container
    const fullscreen_container = this.createContainer()

    fullscreen_container.addEventListener('click',
      () => this._player.player.toggleFullScreen())
    fullscreen_container.addEventListener('touchend',
      () => this._player.player.toggleFullScreen())

    // move icon into fullscreen container
    fullscreen_container.insertAdjacentElement('beforeend', icon_fullscreen)
    fullscreen_container.insertAdjacentElement('beforeend',
      icon_fullscreen_exit)

    // move fullscreen container into controlbar
    if (this.controlbar)
      this.controlbar.insertAdjacentElement('beforeend', fullscreen_container)

    // apply translations to aria labels
    if (this._player.player.translator) {
      const go_full_label = this._player.player.translator.translate(
        'core.fullscreen')
      const exit_full_label = this._player.player.translator.translate(
        'core.exit_fullscreen')

      fullscreen_container.setAttribute('aria-label', go_full_label)

      this._player.player.on('fullscreenenter', () =>
        fullscreen_container.setAttribute('aria-label', exit_full_label),
      )
      this._player.player.on('fullscreenexit', () =>
        fullscreen_container.setAttribute('aria-label', go_full_label),
      )
    }
  }

  getName() {
    return 'FullscreenFlowPlayerFeature'
  }

  createContainer () {
    return createHtmlElementFromString(`
    <div class="fp-togglable fc-fullscreen-button" role="button" tabindex="0" aria-label="Enter Fullscreen"></div>
    `)
  }
}
