/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019-20
 */

import { fcLogger } from '../lib/FcLogger'

export class PlayerFeaturesHandler {
  constructor (features = []) {
    this.list = features
  }

  merge (features) {
    this.list = [...this.list, ...features]
  }

  add (feature) {
    this.list.push(feature)
  }

  get (name) {
    const feature = this.list.find(feature => feature['getName']() === name)

    if (feature)
      return feature

    fcLogger.warn(`feature ${name} not found`)
    return null
  }

  // call a specific function on all features contained in this.list
  call(functionName, value = null) {
    this.list.forEach(feature => {
      if (typeof feature[functionName] === 'function') {
        feature[functionName](value)
      }
    })
  }
}
