/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import _get from 'lodash/get'
import _isUndefined from 'lodash/isUndefined'
import {PlayerFeatureBase} from '../PlayerFeatureBase'

export class FlowPlayerFeatureBase extends PlayerFeatureBase {
  constructor(player) {
    super(player)
    this._player = player

    this.container = this._player.container
    this.videoElement = this.container.querySelector('video')
    this.controlbar = this.container.querySelector('.fp-controls')
    this.timeline = this._player.container.querySelector('.fp-timeline')
    this.cc = this.controlbar ? this.controlbar.querySelector('.cc') : null
    this.isIE11 = !!window.MSInputMethodContext && !!document.documentMode
    this.header = this._player.container.querySelector('.fp-header')
    this.middle = this._player.container.querySelector('.fp-middle')

    this.audioTracks = this.getAudioTracks.bind(this)

    this.goBackToLive = () => this._goBackToLive()
    // expose the function
    this._player['gotoLive'] = this._goBackToLive.bind(this)
    this._player['seek'] = this._seek.bind(this)

    // used by gsc backend app, need to put it back here
    if (_isUndefined(this._player.playerState))
      Object.defineProperty(this._player, 'playerState', { get: () => this.getPlayerState() })
    // we convert tracks that are not with the usual flowplayer payload
    if (_isUndefined(this._player.tracks))
      Object.defineProperty(this._player, 'tracks', { get: () => this.getAudioTracks() })
  }

  handle() {}

  // this function will always receive a config object when invoked
  reload() {}
  destroy() {}

  getPlayerState() {
    return this._player.player.playerState
  }

  getAudioTracks () {
    const mapTrack = (track) => {
      if (track.hasOwnProperty('kind') &&
        track.hasOwnProperty('language') &&
        track.hasOwnProperty('label')) {
        return {
          ...track,
          name: track.label,
          lang: track.language,
        }
      }

      return track
    }

    const aselTracks = _get(this, '_player.player.asel.tracks')
      ? this._player.player.asel.tracks
      : []

    return aselTracks.map(mapTrack)
  }

  _goBackToLive() {
    let hasGoneBackToLive = false
    const liveEl = this.container.querySelector('.fp-live-status')

    if (!liveEl) return

    if (_get(this, '_player.config.live') && !this.isPlayingLatestFragment()) {
      liveEl.emit('click')
      hasGoneBackToLive = true
    }

    return hasGoneBackToLive
  }

  /**
   *
   * @param time {number|string} - Either a currentTime in seconds ou a timestamp
   * @return {boolean} - whether if has jumpt or not
   * @private
   */
  _seek(time) {
    const clipSectionFeature = this._player.fc_features.get('ClipSectionFeature')
    const getCurrentTimeFromTs = (timestamp) => {
      return (timestamp - this._player.config.start) / 1000
    }

    time = Number.parseFloat(time)

    if (typeof time !== 'number') return false

    if (this._player.config.live && Number.parseInt(time).toString().length === 13) {
      const currentTimestampZero = this._player.currentTimestamp - (this._player.currentTime * 1000)
      time = (time - currentTimestampZero) / 1000
    }
    // we need a base timestamp to compute
    else if (typeof this._player.config.start === 'number' && Number.parseInt(time).toString().length === 13) {

      if (clipSectionFeature.hasSections()) {
        const section = clipSectionFeature.clipSections.getSectionInfoByTimestamp(time)

        if (!section) return false

        time = section.currentTimeMs / 1000
      } else {
        time = getCurrentTimeFromTs(time)
      }
    }

    this._player.currentTime = time

    return true
  }

  isPlayingLatestFragment() {
    const fragmentPlayingDT = _get(this, '_player.player.hls.streamController.fragPlaying.programDateTime')
    const latestFragmentDT = _get(this, '_player.player.hls.abrController.fragCurrent.programDateTime')

    if (!latestFragmentDT || !fragmentPlayingDT)
      return console.trace('Playback has not started or hls is not loaded')

    return fragmentPlayingDT === latestFragmentDT
  }
}
