/**
 * @package   freecaster/luna/player
 * @author    Maxime Bartier <maxime.bartier@freecaster.com>
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019-20
 */

export default {
  audiotracks: 'Languages',
  play: 'Play',
  rewind: 'Rewind',
  forward: 'Forward',
  quality: 'Quality',
  fullscreen: 'Fullscreen',
  popout: 'Popout',
}
