/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

// barrel that re-exports all const exported by properties
// located in ./properties

const context = require.context('./properties', false, /\.js$/)
let modules = []

context.keys().forEach(contextModule => modules.push(context(contextModule)))

modules.forEach(contextModule => {
  for (const property in contextModule) {
    module.exports[property] = contextModule[property]
  }
})

