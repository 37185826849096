/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import {FlowPlayerFeatureBase} from '../FlowPlayerFeatureBase'
import _get from 'lodash/get'

export class ThumbnailsFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor(props) {
    super(props)

    if (_get(this, '_player.config.vtt_url')) {
      this.setThumbnails(this._player.config.vtt_url)
    }
  }

  getName() {
    return 'ThumbnailsFlowPlayerFeature'
  }

  handle() {}

  setThumbnails(srcUrl) {
    this._player.player.setOpts({
      thumbnails: { src: srcUrl },
    })
  }
}
