/**
 * LanguagesFlowPlayerFeature client-side code
 *
 * @package   freecaster/luna/player
 * @author    Olivier Deblander
 * @copyright (c) 2019 Freecaster SPRL
 */
import { FlowPlayerFeatureBase } from "../../FlowPlayerFeatureBase";
import _get from 'lodash/get'
import {fcLogger} from '../../../../lib/FcLogger'
import {createHtmlElementFromString} from '../../../../lib/Helper'

export class LanguagesFlowPlayerFeature extends FlowPlayerFeatureBase {
  constructor(player) {
    super(player);

    // some devices (iphones) behave differently for languages
    if (_get(this, 'videoElement.audioTracks') && (/iPhone|iPod/i.test(navigator.userAgent))){
      this._player.player.on('loadeddata', this.onLoadedData.bind(this))
      this.videoElement.audioTracks.addEventListener('change', this.onAudioUpdate.bind(this))
    }
    else {
      this._player.player.on('audio:update', this.onAudioUpdate.bind(this))
    }

  }

  getName() {
    return 'LanguagesFlowPlayerFeature'
  }

  onAudioUpdate (event) {
    const getIdFromEvent = (event) => {
      if (event.data && typeof parseInt(event.data.id) === 'number') return parseInt(event.data.id)
      const tracks = Array.from(event.target)
      if (Array.isArray(tracks) && tracks.length > 0) {
        const currentTrack = tracks.find(track => track.enabled)
        if (currentTrack) return parseInt(currentTrack.id)
      }
    }

    const id = getIdFromEvent(event)

    if (typeof id !== 'number') return

    const track = this._player.tracks.find(track => parseInt(track.id) === id)
    this.renderLanguage(track)
  }

  onLoadedData(event) {
    const currentTrack = Array.from(this.videoElement.audioTracks).find(track => !!track.enabled)

    this.renderLanguage(this._player.tracks.find(track => track.id === currentTrack.id))
  }

  renderLanguage(track) {
    if (!_get(track, 'name')) return fcLogger.warn('Could not render language', track)
    this.getLabelLanguaelEl().innerText = track.name
  }

  getLabelLanguaelEl() {
    let el = this._player.container.querySelector('.newLabelLanguages')

    if (!el) {
      const languagesContainer = this._player.container.querySelector('.fp-asel')
      languagesContainer.classList.add('fc-asel')

      el = createHtmlElementFromString(`<span class="newLabelLanguages"></span>`)

      languagesContainer.insertAdjacentElement('beforeend', el)
    }

    return el
  }
}
