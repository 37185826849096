/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

export class PlayerFeatureBase {
  constructor(player) {
    this._player = player

    if (this.getName === undefined) {
      throw new Error('Features must implement the getName method')
    }
  }

  handle() {}
}
