/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import * as features from './ExportFlowPlayerFeatures'
import _omit from 'lodash/omit'

const featureList = Object.values(_omit(features, 'default'))

export class FlowPlayerFeatures {
  constructor (player) {
    this.fcFeaturesLoaded = false
    this.player = player

    this.load()

    return this.features
  }

  load () {
    if (this.fcFeaturesLoaded) return

    // loop through the exported classes in /features (see ExportFeatures.js)
    // and keep the instance of eachfeatures
    this.features = featureList.map(feature => new feature(this.player))

    this.fcFeaturesLoaded = true
  }
}
