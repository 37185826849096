/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */

import {PlayerFeatureBase} from '../PlayerFeatureBase'
import {getUrlParams} from '../../lib/Helper'
import _isNumber from 'lodash/isNumber'

export class ManageStartEndTimeFeature extends PlayerFeatureBase {
  constructor (player) {
    super(player)

    const urlParams = getUrlParams(location.href)

    this.start_time = urlParams.start_time
    this.end_time = urlParams.end_time

    this._player.player.on('loadeddata', this.handleTimeChange.bind(this))
  }

  getName () {
    return 'ManageStartEndTimeFeature'
  }

  stopTime () {
    const checkTime = setInterval(() => {
      if (this._player.currentTime > this.end_time) {
        this._player.pause()
        clearInterval(checkTime)
      }
    }, 1000)
  }

  handleTimeChange () {
    if (this._player.config.live === true) return
    if (this.start_time && _isNumber(
      this.start_time)) this._player.currentTime = this.start_time
    if (this.end_time && _isNumber(this.start_time)) this.stopTime()
  }
}
