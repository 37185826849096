/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2020
 */
import { getUrlParams } from './Helper'
import _get from 'lodash/get'

class FcLogger {
  constructor () {
    this.isDebug = this.getDebugState()
  }

  getDebugState() {
    const currentParams = getUrlParams(location.href)

    return _get(currentParams, 'debug') === true
      || __DEBUG__ // env variable setup in webpack.mix.js
  }

  _log(method, ...args) {
    if (this.isDebug)
      console[method](...args)
  }

  log(...args) {
    this._log('log', ...args)
  }

  info(...args) {
    this._log('info', ...args)
  }

  warn(...args) {
    this._log('warn', ...args)
  }

  error(...args) {
    this._log('error', ...args)
  }

}

export const fcLogger = new FcLogger()
