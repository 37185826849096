/**
 * @package   freecaster/luna/player
 * @author    François Arki <francois.arki@freecaster.com>
 * @copyright Freecaster 2019
 */

import { EventSourcePolyfill } from 'event-source-polyfill'
import { fcLogger } from '../lib/FcLogger'

export class MercureHandler {
  /**
   *
   * @param callback: the fn called when an event is dispatched
   * @param topics: the resources we are subscribing for
   * @param hubURL
   * @param token
   * @param lastEventId
   * @param fallback: the fn to be called if mercure does not work
   */
  constructor ({callback, config, fallback}) {
    this.hubURL = config.hubURL
    // this token contains the topics the user can subscribe to
    // TODO: token might be to be refreshed after a certain amount a time
    this.token = config.token
    this.authHeaders = {}
    this.topics = config.topics
    this.callback = callback
    this.lastEventId = config.lastEventId
    // after 3 tries, use the fallback fn provided
    this.fallback = fallback
    // TODO: this should be configurable by Luna
    this.reconnectMaxTries = 3
    this.reconnectNb = 0

    try {
      this._setAuthHeaders()
      this._subscribe()
    } catch (error) {
      fcLogger.warn('Could not connect to mercure, using fallback...')
      this.fallback()
    }
  }

  _subscribe () {
    fcLogger.info('Subscribing to hub...')

    let u = new URL(this.hubURL, window.location.href)

    if (this.lastEventId)
      u.searchParams.append('Last-Event-ID', this.lastEventId)

    this.topics.forEach(topic => u.searchParams.append('topic', topic))

    this.eventSource = new EventSourcePolyfill(u, {headers: this.authHeaders})

    this._defineDefaultEvents()
  }

  _setAuthHeaders () {
    this.authHeaders = {Authorization: 'Bearer ' + this.token}
  }

  /**
   * Register onMessage, onOpen, onError on the eventsource obj
   * @param name: name of the event (onMessage, onOpen or onError)
   * @param callback: fn to be called when triggered
   * @private
   */
  _registerEvent (name, callback) {
    this.eventSource[name] = (event) => {
      fcLogger.info({name, event, lastEventId: this.eventSource.lastEventId})

      switch (event.target.readyState) {
        case EventSourcePolyfill.CONNECTING:

          if (this.reconnectNb >= this.reconnectMaxTries) {
            fcLogger.warn('Could not reconnect to mercure, using fallback...')
            this.eventSource.close()
            return this.fallback()
          }

          fcLogger.info('Reconnecting...')
          this.reconnectNb++
          break
        case EventSourcePolyfill.CLOSED:
          fcLogger.info('Connection closed with hub')
          break
      }

      callback(event)
    }
  }

  _defineDefaultEvents () {
    // define default events behavior
    this.onError()
    this.onOpen()
    this.onMessage(this.callback)

    // if a type is specified on the Event, it will be triggered here
    this.topics.forEach(topic => {
      this.eventSource.addEventListener(topic, (event) => {
        this.callback(event)
      })
    })
  }

  onMessage (callback) {
    const callbackFn = callback || (() => {
      fcLogger.warn('No handler for "onmessage" has been setup')
    })

    this._registerEvent('onmessage', callbackFn)
  }

  onError (callback) {
    const callbackFn = callback || (() => {
      fcLogger.warn('An error has occured using Mercure')

      if (this.eventSource.readyState > 1) {
        this.eventSource.close()
        this.fallback()
      }
    })

    this._registerEvent('onerror', callbackFn)
  }

  onOpen (callback) {
    const callbackFn = callback || (() => {
      fcLogger.info('Connection opened with mercure')

      // resets available tries when successfully connected
      this.reconnectNb = 0
    })

    this._registerEvent('onopen', callbackFn)
  }
}
